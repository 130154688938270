import { Dayjs } from 'dayjs'
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import {
  AppointmentInfoResult,
  AppointmentType,
  AuthorizedPerson,
  BookingInstructions,
  ReservationPaymentType,
  SearchNode,
  SupportedLanguage,
  User,
  UserWithDelegateRole,
} from '../../../__generated__/api'
import { useApi } from '../../../common/hooks/useApi'
import { useAppointmentTitle } from '../../../common/hooks/useAppointmentTitle'
import { useLockAppointment } from '../../../common/hooks/useLockAppointment'
import useLoginState from '../../../common/hooks/useLoginState'
import { useNode } from '../../../common/hooks/useNode'
import { useOHC } from '../../../common/hooks/useOHC'
import api from '../../../common/services/api'
import { ReserveAppointmentNotFoundError } from '../../../common/utils/error/ReserveAppointmentNotFoundError'
import { ReserveCallbackNotAllowed } from '../../../common/utils/error/ReserveCallbackNotAllowed'
import {
  AppointmentId,
  selectedAppointmentNodeConnectionIdAtom,
  selectedAppointmentNodeIdAtom,
  selectedAppointmentServiceIdAtom,
  selectedInsuranceAtom,
  selectedInsurancePayerAtom,
  selectedSearchInsuranceAtom,
} from '../../../state/common/atoms'
import { bookedForSelfAtom, preSelectedUserOmIdAtom } from '../../../state/reserve/atoms'
import { LoginStatus, teliaJWTAtom } from '../../../state/user/atoms'
import type { InsuranceAdditionalDetailsType } from '../components/InsuranceAdditionalDetails'
import { getEmptyUser, getUserFromToken } from '../components/utils'

import useUserState from './useUserState'

type ReserveStateContextType = {
  appointmentId: AppointmentId
  appointmentTitle?: string
  loginStatus: LoginStatus
  users?: AuthorizedPerson[]
  userFromToken: User
  userPending: boolean
  selectedAppointmentLength: number | 'default'
  selectedAppointmentType: AppointmentType
  loggedInUser: UserWithDelegateRole | undefined
  selectedUser: User
  setSelectedUser(user: User): void
  fetchUser(omUid: number): Promise<User>
  selectedNodeId?: string
  selectedNodeConnectionId?: string
  selectedServiceId?: number
  node: SearchNode | null
  nodePending: boolean
  appointmentInfo: AppointmentInfoResult | null
  selectedInsuranceContractId: number | null
  setSelectedInsuranceContractId(id: number | null): void
  selectedSearchInsuranceContractId: number | null
  selectedInsurancePayerId: string | null
  setSelectedInsurancePayerId(id: string | null): void
  insuranceAdditionalDetails?: InsuranceAdditionalDetailsType
  setInsuranceAdditionalDetails(details?: InsuranceAdditionalDetailsType): void
  paymentType: ReservationPaymentType
  setPaymentType(value: ReservationPaymentType): void
  paymentTypeOptions: ReservationPaymentType[]
  bookingInstructions?: BookingInstructions | null
  lockReleaseDateTime?: Dayjs
  isDentalAppointment: boolean
}

const fetchUser = async (omUid: number) => {
  const response = await api.v1.getPrincipalUser(omUid)
  return response.data
}

const ReserveStateContext = createContext<ReserveStateContextType>({
  appointmentId: 1,
  selectedAppointmentLength: 'default',
  selectedAppointmentType: AppointmentType.Clinic,
  selectedUser: getEmptyUser(),
  setSelectedUser: () => {
    /* Placeholder */
  },
  fetchUser: fetchUser,
  selectedNodeId: undefined,
  selectedNodeConnectionId: undefined,
  selectedServiceId: undefined,
  appointmentTitle: undefined,
  loginStatus: undefined,
  loggedInUser: undefined,
  users: undefined,
  userPending: true,
  userFromToken: getEmptyUser(),
  node: null,
  nodePending: true,
  appointmentInfo: null,
  selectedInsuranceContractId: null,
  setSelectedInsuranceContractId: () => {
    /* Placeholder */
  },
  selectedSearchInsuranceContractId: null,
  selectedInsurancePayerId: null,
  setSelectedInsurancePayerId: () => {
    /* Placeholder */
  },
  insuranceAdditionalDetails: undefined,
  setInsuranceAdditionalDetails: () => {
    /* Placeholder */
  },
  paymentType: ReservationPaymentType.Self,
  setPaymentType: () => {
    /* Placeholder */
  },
  paymentTypeOptions: [ReservationPaymentType.Self],
  lockReleaseDateTime: undefined,
  isDentalAppointment: false,
})

export const ReserveStateProvider = ({
  appointmentId,
  appointmentType,
  appointmentLength,
  isReserved,
  children,
}: {
  appointmentId: AppointmentId
  appointmentType: AppointmentType
  appointmentLength: 'default' | number
  isReserved: boolean
  children: ReactNode
}): JSX.Element => {
  const { i18n } = useTranslation()

  // User data
  const { loginStatus } = useLoginState()
  const jwtToken = useRecoilValue(teliaJWTAtom)
  const setBookedForSelf = useSetRecoilState(bookedForSelfAtom)
  const preSelectedUserOmUid = useRecoilValue(preSelectedUserOmIdAtom)
  const selectedServiceId = useRecoilValue(selectedAppointmentServiceIdAtom)
  const [fetchUserPending, setFetchUserPending] = useState<boolean>(true)
  const { isOHCSide } = useOHC()

  const {
    user: loggedInUser,
    pending: loggedInUserPending,
    authorizations: users,
  } = useUserState(loginStatus)

  const userFromToken = getUserFromToken(jwtToken)
  const [selectedUser, setSelectedUser] = useState<User>(userFromToken)
  useEffect(() => {
    if (loggedInUser) {
      setSelectedUser(loggedInUser)
    }
  }, [setSelectedUser, loggedInUser])

  useEffect(() => {
    if (loggedInUserPending) return
    if (preSelectedUserOmUid && preSelectedUserOmUid !== loggedInUser?.omUid) {
      fetchUser(preSelectedUserOmUid).then((user) => {
        setSelectedUser(user)
        setFetchUserPending(false)
      })
    } else {
      setFetchUserPending(false)
    }
  }, [loggedInUser?.omUid, preSelectedUserOmUid, loggedInUserPending])

  useEffect(() => {
    if (selectedUser) {
      setBookedForSelf(selectedUser.ssn === loggedInUser?.ssn)
    }
  }, [loggedInUser?.ssn, selectedUser, setBookedForSelf])

  const { data: callbackReserveAllowedData, error: callbackReserveAllowedError } = useApi(
    api.v1.callbackReserveAllowed,
    {
      appointmentId: appointmentId,
      ssn: selectedUser.ssn,
      isOhc: isOHCSide,
    },
    null,
    !fetchUserPending && appointmentType === AppointmentType.Callback
  )

  // Appointment related data
  const selectedNodeId = useRecoilValue(selectedAppointmentNodeIdAtom)
  const selectedNodeConnectionId = useRecoilValue(selectedAppointmentNodeConnectionIdAtom)
  const selectedAppointmentNodeId = useRecoilValue(selectedAppointmentNodeIdAtom)
  const { data: appointmentInfo, error: appointmentInfoError } = useApi(
    api.v1.appointmentInfo,
    {
      appointmentId: String(appointmentId),
      lang: i18n.language as SupportedLanguage,
    },
    null
  )
  const { node, pending: nodePending } = useNode(selectedAppointmentNodeId)
  const appointmentTitle = useAppointmentTitle()

  // Insurance
  const [selectedInsuranceContractId, setSelectedInsuranceContractId] =
    useRecoilState(selectedInsuranceAtom)
  const selectedSearchInsuranceContractId = useRecoilValue(selectedSearchInsuranceAtom)
  const [selectedInsurancePayerId, setSelectedInsurancePayerId] = useRecoilState(
    selectedInsurancePayerAtom
  )
  const [insuranceAdditionalDetails, setInsuranceAdditionalDetails] =
    useState<InsuranceAdditionalDetailsType>()

  // Payment type
  const [paymentType, setPaymentType] = useState<ReservationPaymentType>(
    ReservationPaymentType.Self
  )
  const { data: reservationPaymentTypesResponse } = useApi(
    api.v1.reservationPaymentTypes,
    { appointmentId: String(appointmentId) },
    undefined,
    true
  )
  const paymentTypeOptions = useMemo(
    () => reservationPaymentTypesResponse?.paymentTypes ?? [ReservationPaymentType.Self],
    [reservationPaymentTypesResponse]
  )

  const { data: bookingInstructions } = useApi(
    api.v1.getBookingInstructions,
    { lang: i18n.language, serviceId: selectedServiceId, nodeId: selectedNodeId },
    null,
    Boolean(selectedNodeId || selectedServiceId)
  )

  const { lockReleaseDateTime } = useLockAppointment(
    appointmentId,
    isReserved,
    appointmentLength === 'default' ? undefined : appointmentLength,
    selectedInsuranceContractId === null ? undefined : 1800 // Extends lock length if handling an insurance booking
  )

  const isDentalAppointment = useMemo(() => typeof appointmentId === 'string', [appointmentId])

  if (appointmentInfoError) {
    if (appointmentInfoError.statusCode === 404) {
      throw new ReserveAppointmentNotFoundError()
    }
    throw appointmentInfoError
  }

  if (
    callbackReserveAllowedError ||
    (callbackReserveAllowedData && !callbackReserveAllowedData.callbackAllowed)
  ) {
    throw new ReserveCallbackNotAllowed()
  }

  const memoized = useMemo<ReserveStateContextType>(
    () => ({
      appointmentId,
      appointmentTitle,
      selectedNodeId,
      selectedNodeConnectionId,
      selectedServiceId,
      selectedUser,
      setSelectedUser,
      fetchUser: fetchUser,
      loginStatus,
      loggedInUser: loggedInUser,
      userInfo: loggedInUser,
      users,
      userPending: loggedInUserPending,
      userFromToken,
      node,
      nodePending,
      appointmentInfo,
      selectedInsuranceContractId:
        selectedInsuranceContractId === 'other' ? null : selectedInsuranceContractId,
      setSelectedInsuranceContractId,
      selectedSearchInsuranceContractId:
        selectedSearchInsuranceContractId === 'other' ? null : selectedSearchInsuranceContractId,
      selectedInsurancePayerId,
      setSelectedInsurancePayerId,
      insuranceAdditionalDetails,
      setInsuranceAdditionalDetails,
      selectedAppointmentType: appointmentType,
      selectedAppointmentLength: appointmentLength,
      paymentType,
      setPaymentType,
      paymentTypeOptions,
      bookingInstructions,
      lockReleaseDateTime,
      isDentalAppointment,
    }),
    [
      appointmentId,
      appointmentTitle,
      selectedNodeId,
      selectedNodeConnectionId,
      selectedServiceId,
      selectedUser,
      setSelectedUser,
      loginStatus,
      loggedInUser,
      users,
      loggedInUserPending,
      userFromToken,
      node,
      nodePending,
      appointmentInfo,
      appointmentType,
      appointmentLength,
      selectedInsuranceContractId,
      setSelectedInsuranceContractId,
      selectedSearchInsuranceContractId,
      selectedInsurancePayerId,
      setSelectedInsurancePayerId,
      insuranceAdditionalDetails,
      setInsuranceAdditionalDetails,
      paymentType,
      setPaymentType,
      paymentTypeOptions,
      bookingInstructions,
      lockReleaseDateTime,
      isDentalAppointment,
    ]
  )

  return <ReserveStateContext.Provider value={memoized}>{children}</ReserveStateContext.Provider>
}

const useReserveState = () => {
  return useContext(ReserveStateContext)
}

export default useReserveState
