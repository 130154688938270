import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Primary600 } from '@mehilainen/design-system-tokens/colors'
import { LoadingButton } from '@mui/lab'
import { Button, ButtonProps } from '@mui/material'

import AnchorButton from '../AnchorButton/AnchorButton'

export const PrimaryHover = css`
  &:hover {
    background: ${Primary600};
  }
`

const defaultButtonCss = (props: ButtonProps) => css`
  max-width: 366px;
  height: 56px;
  width: 100%;
  text-transform: none;
  font-size: 16px;

  ${props.variant !== 'outlined' && props.variant !== 'text' && PrimaryHover}
  ${props.variant === 'text' &&
  css`
    font-weight: 500;
  `}
`

export const DefaultButton = styled(Button)`
  ${(props) => defaultButtonCss(props)}
`

export const DefaultAnchorButton = styled(AnchorButton)`
  ${(props) => defaultButtonCss(props)}
`

export const DefaultLoadingButton = styled(LoadingButton)`
  ${(props) => defaultButtonCss(props)}
`
