import styled from '@emotion/styled'
import { White } from '@mehilainen/design-system-tokens/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'

import { SearchNode } from '../../../__generated__/api'
import { DefaultButton } from '../../../common/components/DefaultButton/DefaultButton'
import { CenteredColumnFlex } from '../../../common/components/Layout/Layout'
import Pattern from '../../../common/components/Pattern/Pattern'
import SearchItems, { SearchItemsContainer } from '../../../common/components/Search/SearchItems'
import {
  convertSearchNodes,
  searchTreeItemIconProvider,
} from '../../../common/components/Search/utils'
import { Text } from '../../../common/components/Typography/Typography'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import { isUserSelectedNodeAtom, searchBreadcrumb } from '../../../state/search/atoms'
import useSearchTarget, { SearchTargetValue } from '../hooks/useSearchTarget'

const PatternContainer = styled.div`
  ${Pattern}
  padding-top: 60px;
`

const Container = styled(CenteredColumnFlex)`
  min-height: 100%;
  padding-top: 25px;
  margin-bottom: 25px;
  background: ${White};
`

const Wrapper = styled.div`
  width: 95%;
  max-width: 688px;
`

const HeaderText = styled(Text)`
  text-align: center;
`

const DescriptionText = styled(Text)`
  margin-top: 18px;
  margin-bottom: 31px;
  text-align: center;
`

const SearchItemsWrapper = styled(CenteredColumnFlex)<{ isMobile: boolean }>`
  ${SearchItemsContainer} {
    width: ${(props) => (props.isMobile ? 'auto' : '538px')};
  }
`

const ButtonContainer = styled(CenteredColumnFlex)`
  margin-top: 28px;
`

interface Props {
  selectedParentNode: SearchNode
}

const ParentNodeChildSelect: React.VFC<Props> = ({ selectedParentNode }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const parentNodeChildren = convertSearchNodes(
    selectedParentNode.children!,
    selectedParentNode.name
  )
  const setBreadcrumb = useSetRecoilState(searchBreadcrumb)
  const setIsUserSelectedNode = useSetRecoilState(isUserSelectedNodeAtom)
  const { setSearchTarget } = useSearchTarget()

  return (
    <PatternContainer>
      <Container>
        <Wrapper>
          <HeaderText $size={500} as="h1">
            {selectedParentNode.name}
          </HeaderText>
          <DescriptionText $size={300} $height="Large" as="p">
            {t('component.searchView.parentNodeChildSelect.description')}
          </DescriptionText>
          <SearchItemsWrapper isMobile={isMobile}>
            <SearchItems
              items={parentNodeChildren}
              onSelect={(item) => {
                setSearchTarget({ id: item.id.toString(), value: SearchTargetValue.Node })
                setBreadcrumb([selectedParentNode])
                setIsUserSelectedNode(true)
              }}
              iconProvider={searchTreeItemIconProvider}
            />
          </SearchItemsWrapper>
          <ButtonContainer>
            <DefaultButton
              variant="outlined"
              onClick={() => window.location.assign(process.env.REACT_APP_BASE_BATH ?? '/av3')}
            >
              {t('component.searchView.parentNodeChildSelect.backToReservation')}
            </DefaultButton>
          </ButtonContainer>
        </Wrapper>
      </Container>
    </PatternContainer>
  )
}

export default ParentNodeChildSelect
