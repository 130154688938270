import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AppointmentInfoResult, SearchNode, AuthorizedPerson } from '../../../__generated__/api'
import * as Analytics from '../../../common/utils/analytics'
import { scale } from '../../utils/scale'
import { DefaultButton } from '../DefaultButton/DefaultButton'
import DelegationUserSelect, {
  ButtonGroupContainer,
  SelectUserOptionButton,
} from '../DelegationUserSelect/DelegationUserSelect'
import { CenteredColumnFlex, MinMaxDiv } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const Container = styled(CenteredColumnFlex)`
  margin: ${scale(2)};
  gap: ${scale(1.5)};

  & > ${Text} {
    text-align: center;
  }
`

interface Props {
  users?: AuthorizedPerson[]
  onSelectUser(omUid: number | undefined): void
  onNewUser(omUid: number): void
  onReserveSelf(): void
  onBack?(): void
  appointmentInfo: AppointmentInfoResult | null
  node: SearchNode | null
  nodePending: boolean
  disableUndefinedSelection?: boolean
}

const UserSelect: React.FC<React.PropsWithChildren<Props>> = ({
  users,
  onSelectUser,
  onNewUser,
  onReserveSelf,
  onBack,
  appointmentInfo,
  node,
  nodePending,
  disableUndefinedSelection,
}) => {
  const { t } = useTranslation()
  const [shouldDisplayDelegateView, setshouldDisplayDelegateView] = useState<boolean>(false)

  Analytics.useTrackVerify({
    appointmentInfo,
    node,
    nodePending,
    event: 'select_patient',
  })

  if (shouldDisplayDelegateView) {
    return (
      <DelegationUserSelect
        options={users}
        onSelectUser={onSelectUser}
        onNewUser={onNewUser}
        onBack={() => {
          setshouldDisplayDelegateView(false)
        }}
        disableUndefinedSelection={disableUndefinedSelection}
      />
    )
  }
  return (
    <MinMaxDiv size="620px">
      <Container>
        <Text as="h1" $size={500} $weight="Medium" $height="Medium">
          {t('component.userSelect.heading')}
        </Text>
        <Text as="p" $size={300} $weight="Regular" $height="Large">
          {!disableUndefinedSelection
            ? t('component.userSelect.paragraph')
            : t('component.userSelect.paragraphCallback')}
        </Text>
        <ButtonGroupContainer>
          <SelectUserOptionButton
            onClick={() => onReserveSelf()}
            text={t('component.reserveUserSelect.modal.self')}
          />
          <SelectUserOptionButton
            onClick={() => setshouldDisplayDelegateView(true)}
            text={t('component.userSelect.anotherPerson')}
          />
        </ButtonGroupContainer>
        {onBack !== undefined && (
          <DefaultButton variant="outlined" onClick={onBack}>
            {t('common.back')}
          </DefaultButton>
        )}
      </Container>
    </MinMaxDiv>
  )
}

export default UserSelect
