import equal from 'fast-deep-equal'
import React, { useCallback, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import {
  AppointmentType,
  LocationResult,
  PractitionerDetails,
  SearchNode,
} from '../../../__generated__/api'
import LocationSelect from '../../../common/components/LocationSelect/LocationSelect'
import PractitionerLocationSelect from '../../../common/components/LocationSelect/PractitionerLocationSelect'
import { useOHC } from '../../../common/hooks/useOHC'
import {
  allLocationsSelection,
  isUserSelectedNodeAtom,
  selectedAppointmentTypesAtom,
  selectedNodeLocationAtom,
  selectedPractitionerLocationAtom,
} from '../../../state/search/atoms'
import useSearchTarget, { SearchTargetValue } from '../hooks/useSearchTarget'

interface Props {
  locations: LocationResult[]
  selectedPractitioner?: PractitionerDetails
  selectedNode: SearchNode | null
}

const SearchLocationSelect: React.FC<React.PropsWithChildren<Props>> = ({
  locations,
  selectedPractitioner,
  selectedNode,
}) => {
  const { searchTarget, setSearchTarget } = useSearchTarget()
  const [selectedNodeLocation, setSelectedNodeLocation] = useRecoilState(selectedNodeLocationAtom)
  const [selectedPractitionerLocation, setSelectedPractitionerLocation] = useRecoilState(
    selectedPractitionerLocationAtom
  )
  const setSelectedAppointmentTypes = useSetRecoilState(selectedAppointmentTypesAtom)
  const isUserSelectedNode = useRecoilValue(isUserSelectedNodeAtom)
  const { isOHCSide } = useOHC()

  const setLocation = useCallback(
    (location: string[]) => {
      if (equal(location, allLocationsSelection)) {
        setSelectedAppointmentTypes([AppointmentType.Video, AppointmentType.Phone])
      }

      if (selectedPractitioner) {
        setSelectedPractitionerLocation(location)
      } else {
        setSelectedNodeLocation(location)
      }
    },
    [
      selectedPractitioner,
      setSelectedAppointmentTypes,
      setSelectedNodeLocation,
      setSelectedPractitionerLocation,
    ]
  )

  useEffect(() => {
    if (selectedPractitioner) {
      setSelectedPractitionerLocation([])
    }
  }, [selectedPractitioner, setSelectedPractitionerLocation])

  useEffect(() => {
    if (selectedNodeLocation.length === 0) {
      return
    }
    const location = locations.find((loc) => loc.uniqueId === selectedNodeLocation[0])
    if (
      location &&
      !isUserSelectedNode &&
      searchTarget.value === SearchTargetValue.Node &&
      location.defaultNode &&
      location.defaultNode !== searchTarget.id &&
      !isOHCSide
    ) {
      setSearchTarget({ id: location.defaultNode, value: SearchTargetValue.Node })
    }
  }, [
    isOHCSide,
    isUserSelectedNode,
    locations,
    searchTarget.id,
    searchTarget.value,
    selectedNodeLocation,
    setSearchTarget,
  ])

  if (selectedPractitioner) {
    return (
      <PractitionerLocationSelect
        practitioner={selectedPractitioner}
        allLocations={locations}
        value={
          selectedPractitionerLocation.length === 1 ? selectedPractitionerLocation[0] : undefined
        }
        onSelect={(location) =>
          location
            ? setSelectedPractitionerLocation([location])
            : setSelectedPractitionerLocation([])
        }
      />
    )
  }

  const filteredLocations =
    selectedNode && !selectedNode.video && !selectedNode.phone
      ? locations.filter((location) => !equal([location.uniqueId], allLocationsSelection))
      : locations

  return (
    <LocationSelect
      locations={filteredLocations}
      value={selectedNodeLocation}
      onSelect={setLocation}
      selectedNode={selectedNode}
    />
  )
}

export default SearchLocationSelect
