import equal from 'fast-deep-equal'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { AppointmentType } from '../../__generated__/api'
import {
  allLocationsSelection,
  defaultSelectedLocation,
  selectedAppointmentTypesAtom,
  userSelectedAppointmentTypesAtom,
  selectedNodeLocationAtom,
} from '../../state/search/atoms'
import { getSelectedNodeOrDefault } from '../../state/search/selectors'

import { useNode } from './useNode'

export const useNodeSideEffects = () => {
  const selectedNodeId = useRecoilValue(getSelectedNodeOrDefault)
  const { node: selectedNode } = useNode(selectedNodeId)
  const setSelectedAppointmentTypes = useSetRecoilState(selectedAppointmentTypesAtom)
  const setUserSelectedAppointmentTypes = useSetRecoilState(userSelectedAppointmentTypesAtom)
  const [selectedLocation, setSelectedLocation] = useRecoilState(selectedNodeLocationAtom)

  useEffect(() => {
    // Previous search was a node without remote support
    if (
      selectedNode &&
      selectedNodeId !== selectedNode.id &&
      !selectedNode.video &&
      !selectedNode.phone
    ) {
      setSelectedAppointmentTypes([])
    } else if (selectedNode && selectedNodeId === selectedNode.id) {
      setUserSelectedAppointmentTypes([])
      const allowedTypes = [AppointmentType.Clinic]
      if (selectedNode.video) {
        allowedTypes.push(AppointmentType.Video)
      }
      if (selectedNode.phone) {
        allowedTypes.push(AppointmentType.Phone)
      }
      if (allowedTypes.length === 3) {
        setSelectedAppointmentTypes([])
      } else {
        setSelectedAppointmentTypes(allowedTypes)
      }
      if (
        allowedTypes.length === 1 &&
        allowedTypes[0] === AppointmentType.Clinic &&
        equal(selectedLocation, allLocationsSelection)
      ) {
        // Reset location if remote location is set
        setSelectedLocation(defaultSelectedLocation)
      }
    }
  }, [
    selectedNodeId,
    selectedNode,
    setSelectedAppointmentTypes,
    setUserSelectedAppointmentTypes,
    setSelectedLocation,
    selectedLocation,
  ])
}
