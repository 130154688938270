import styled from '@emotion/styled'
import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'

import {
  AppointmentType,
  ReservationPaymentType,
  ReserveResult,
  SupportedLanguage,
} from '../../../__generated__/api'
import { MinMaxDiv } from '../../../common/components/Layout/Layout'
import UserSelect from '../../../common/components/UserSelect/UserSelect'
import { useApi } from '../../../common/hooks/useApi'
import { useInsurancePayerOption } from '../../../common/hooks/useInsurance'
import { useOHC } from '../../../common/hooks/useOHC'
import i18n from '../../../common/i18n/i18n'
import api from '../../../common/services/api'
import { scale } from '../../../common/utils/scale'
import {
  isFromAppAtom,
  isRedirectedFromInsuranceProviderAtom,
  selectedAppointmentIdAtom,
  selectedAppointmentLengthAtom,
  selectedAppointmentNodeConnectionIdAtom,
  selectedAppointmentNodeIdAtom,
  selectedAppointmentTypeAtom,
} from '../../../state/common/atoms'
import { initialReserveHistoryRestoredAtom } from '../../../state/reserve/atoms'
import InsuranceOptionsSelectView from '../../insuranceOptionsSelect/container/InsuranceOptionsSelectView'
import { isPaidByOHC } from '../../payerSelection/utils'
import InsuranceAdditionalDetails from '../components/InsuranceAdditionalDetails'
import PaymentTypeSelect from '../components/PaymentTypeSelect'
import ReserveForm from '../components/ReserveForm'
import { getEmptyUser } from '../components/utils'
import useReserveHistory from '../hooks/useReserveHistory'
import useReserveState, { ReserveStateProvider } from '../hooks/useReserveState'

const Container = styled.div`
  min-height: 100%;
  padding-bottom: ${scale(12)};
`

const Skeletons = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${scale(2)};
  gap: ${scale(1.5)};
`

const ReserveView: React.FC<React.PropsWithChildren<Props>> = ({
  onBack,
  onReserved,
  onBackToStart,
}) => {
  const {
    appointmentId,
    loginStatus,
    loggedInUser,
    selectedUser,
    setSelectedUser,
    fetchUser,
    userPending,
    users,
    node,
    nodePending,
    selectedInsuranceContractId,
    setSelectedInsuranceContractId,
    selectedSearchInsuranceContractId,
    selectedInsurancePayerId,
    setSelectedInsurancePayerId,
    setInsuranceAdditionalDetails,
    setPaymentType,
    paymentTypeOptions,
    selectedAppointmentType,
    isDentalAppointment,
  } = useReserveState()

  const { isOHCSide } = useOHC()
  const isCallbackAppointment = selectedAppointmentType === AppointmentType.Callback
  const selectedAppointmentNodeId = useRecoilValue(selectedAppointmentNodeIdAtom)
  const isRedirectedFromInsuranceProvider = useRecoilValue(isRedirectedFromInsuranceProviderAtom)
  const selectedNodeConnectionId = useRecoilValue(selectedAppointmentNodeConnectionIdAtom)
  const { data: appointmentInfo } = useApi(
    api.v1.appointmentInfo,
    {
      appointmentId: String(appointmentId),
      lang: i18n.language as SupportedLanguage,
      ...(isOHCSide && {
        nodeId: selectedAppointmentNodeId,
        connectionId: selectedNodeConnectionId,
        isOHC: true,
      }),
    },
    null,
    Boolean(appointmentId)
  )

  const isFromApp = useRecoilValue(isFromAppAtom)

  const [shouldDisplayUserSelect, setShouldDisplayUserSelect] = useState<boolean>(!isFromApp)
  const [shouldDisplayPaymentTypeSelect, setShouldDisplayPaymentTypeSelect] =
    useState<boolean>(true)
  const [shouldDisplayInsuranceOptionSelect, setShouldDisplayInsuranceOptionSelect] =
    useState<boolean>(Boolean(selectedInsuranceContractId))

  const { insurancePayerOption } = useInsurancePayerOption(
    selectedInsuranceContractId,
    selectedInsurancePayerId
  )
  const [showAdditionalInsuranceDetails, setShowAdditionalInsuranceDetails] =
    useState<boolean>(true)

  // Set payment type to occupational for OHC side
  useEffect(() => {
    if (isOHCSide) {
      if (
        appointmentInfo &&
        ((selectedAppointmentType === AppointmentType.Clinic &&
          appointmentInfo.ohcClinicAppointmentRule &&
          isPaidByOHC(appointmentInfo.ohcClinicAppointmentRule)) ||
          (selectedAppointmentType === AppointmentType.Video &&
            appointmentInfo.ohcVideoAppointmentRule &&
            isPaidByOHC(appointmentInfo.ohcVideoAppointmentRule)) ||
          (selectedAppointmentType === AppointmentType.Phone &&
            appointmentInfo.ohcPhoneAppointmentRule &&
            isPaidByOHC(appointmentInfo.ohcPhoneAppointmentRule)))
      ) {
        setPaymentType(ReservationPaymentType.Occupational)
      } else {
        // Selected payment type is not covered by OHC
        setPaymentType(ReservationPaymentType.Self)
      }
    }
  }, [appointmentInfo, isOHCSide, selectedAppointmentType, setPaymentType])

  if (!selectedUser || (userPending && Boolean(loginStatus))) {
    return (
      <MinMaxDiv size="620px">
        <Skeletons>
          <Skeleton variant="rectangular" height="56px" />
          <Skeleton variant="rectangular" height="71px" />
          <Skeleton variant="rectangular" height="136px" />
          <Skeleton variant="rectangular" height="56px" />
        </Skeletons>
      </MinMaxDiv>
    )
  }

  if (!isRedirectedFromInsuranceProvider) {
    if (
      loginStatus === 'authenticated' &&
      loggedInUser &&
      loggedInUser.isDelegateUser &&
      !isOHCSide &&
      shouldDisplayUserSelect
    ) {
      return (
        <UserSelect
          users={users}
          onSelectUser={async (omUid) => {
            if (!omUid) {
              setSelectedUser(getEmptyUser())
            } else {
              setSelectedUser(await fetchUser(omUid))
            }
            setShouldDisplayUserSelect(false)
          }}
          onNewUser={async (omUid) => {
            const user = await fetchUser(omUid)
            users?.push(user)
            setSelectedUser(user)
            setShouldDisplayUserSelect(false)
          }}
          onBack={onBack}
          onReserveSelf={() => {
            setSelectedUser(loggedInUser)
            setShouldDisplayUserSelect(false)
          }}
          appointmentInfo={appointmentInfo}
          node={node}
          nodePending={nodePending}
          disableUndefinedSelection={Boolean(selectedInsuranceContractId) || isCallbackAppointment}
        />
      )
    }

    if (
      ((!selectedSearchInsuranceContractId && !selectedInsuranceContractId) ||
        isDentalAppointment) &&
      !isOHCSide &&
      shouldDisplayPaymentTypeSelect
    ) {
      return (
        <PaymentTypeSelect
          options={paymentTypeOptions}
          onSelect={(paymentType) => {
            setShouldDisplayPaymentTypeSelect(false)
            setPaymentType(paymentType)
          }}
          onBack={onBack}
          onBackToStart={onBackToStart}
          initialSelection={
            isDentalAppointment && selectedSearchInsuranceContractId
              ? ReservationPaymentType.Insurance
              : undefined
          }
        />
      )
    }

    if (
      selectedInsuranceContractId &&
      (!selectedInsurancePayerId || shouldDisplayInsuranceOptionSelect) &&
      !isDentalAppointment
    ) {
      return (
        <InsuranceOptionsSelectView
          insuranceId={selectedInsuranceContractId}
          onSelect={(payerId) => {
            setSelectedInsurancePayerId(payerId)
            setShouldDisplayInsuranceOptionSelect(false)
          }}
          onBack={onBack}
        />
      )
    }
  }

  if (
    insurancePayerOption?.additionalInfoForm &&
    showAdditionalInsuranceDetails &&
    !isDentalAppointment
  ) {
    return (
      <InsuranceAdditionalDetails
        additionalInfoForm={insurancePayerOption.additionalInfoForm}
        onSubmitDetails={(details) => {
          setInsuranceAdditionalDetails(details)
          setShowAdditionalInsuranceDetails(false)
        }}
        onBack={onBack}
        onContinueAsSelfPaying={() => {
          setSelectedInsuranceContractId(null)
          setSelectedInsurancePayerId(null)
          setPaymentType(ReservationPaymentType.Self)
          setShowAdditionalInsuranceDetails(false)
        }}
      />
    )
  }

  return <ReserveForm onReserved={onReserved} onBack={onBack} />
}

interface Props {
  onBack(): void
  onBackToStart(): void
  onReserved(reserveResult: ReserveResult): void
}

const ReserveViewWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  onReserved,
  onBack,
  onBackToStart,
}) => {
  useReserveHistory()

  const { appointmentId: appointmentIdFromParams } = useParams<{ appointmentId?: string }>()
  const [appointmentId, setAppointmentId] = useRecoilState(selectedAppointmentIdAtom)
  const appointmentType = useRecoilValue(selectedAppointmentTypeAtom)
  const appointmentLength = useRecoilValue(selectedAppointmentLengthAtom)
  const [isReserved, setIsReserved] = useState<boolean>(false)

  useEffect(() => {
    if (!appointmentId && appointmentIdFromParams && parseInt(appointmentIdFromParams, 10)) {
      setAppointmentId(parseInt(appointmentIdFromParams, 10))
    }
  }, [appointmentId, appointmentIdFromParams, setAppointmentId])

  const initialReserveHistoryRestored = useRecoilValue(initialReserveHistoryRestoredAtom)

  if (!appointmentId || !initialReserveHistoryRestored) {
    return null
  }

  if (!appointmentType) {
    throw new Error('selectedAppointmentType is not set')
  }

  if (!appointmentLength) {
    throw new Error('appointmentLength is not set')
  }

  const onReserve = (reserveResult: ReserveResult) => {
    setIsReserved(true)
    onReserved(reserveResult)
  }

  return (
    <ReserveStateProvider
      appointmentId={appointmentId}
      appointmentType={appointmentType}
      appointmentLength={appointmentLength}
      isReserved={isReserved}
    >
      <Container>
        <ReserveView onReserved={onReserve} onBack={onBack} onBackToStart={onBackToStart} />
      </Container>
    </ReserveStateProvider>
  )
}

export default ReserveViewWrapper
