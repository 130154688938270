import styled from '@emotion/styled'
import {
  White,
  Primary500,
  Gray100,
  Gray300,
  Gray700,
} from '@mehilainen/design-system-tokens/colors'
import { AngleRight } from '@mehilainen/mds-customer/icons'
import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useIsMobile } from '../../hooks/useBreakpoint'
import { breakpoint } from '../../utils/breakpoint'
import { scale } from '../../utils/scale'
import ComponentWithIcon from '../ComponentWithIcon/ComponentWithIcon'
import { ColumnFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

import { SearchTreeItem } from './types'

const SearchItemButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px;
  background: ${White};
  cursor: pointer;
  border: 1px solid ${Gray300};
  margin: 0;
  color: black;

  :not(:last-of-type) {
    border-bottom: 0;
  }

  :first-of-type {
    border-radius: 8px 8px 0 0;
  }

  :last-of-type {
    border-radius: 0 0 8px 8px;
  }

  :last-of-type:first-of-type {
    border-radius: 8px;
  }

  &:hover,
  &:focus {
    background: ${Gray100};
  }
`

const Container = styled.div`
  display: flex;

  @media (max-width: ${breakpoint.sm}px) {
    flex-wrap: wrap;
  }
`

const SearchItemGroup = styled(ColumnFlex)`
  margin-bottom: 12px;

  .MuiButton-root {
    margin-top: 16px;
  }
`

const SearchItemGroupTitle = styled(Text)`
  padding: 0 16px;
  margin-top: ${scale(3)};
  margin-bottom: ${scale(2)};
`

SearchItemGroupTitle.defaultProps = { $size: 400, $weight: 'Medium', $height: 'Medium' }

const SearchItemName = styled.span<{ isMobile: boolean }>`
  font-size: ${(props) => (props.isMobile ? '14px' : '15px')};
  text-align: left;
  white-space: pre-wrap;
  color: black;
  float: left;
`

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  padding: 2px;
  background: ${White};
`

const Subtitle = styled.span`
  color: ${Primary500};
  margin-left: 16px;

  @media (max-width: ${breakpoint.sm}px) {
    width: 100%;
    text-align: left;
    margin-left: 0;
    margin-top: 4px;
  }
`

const Caption = styled.span<{ isMobile: boolean }>`
  text-align: left;
  display: block;
  font-size: 12px;
  color: ${Gray700};
  line-height: ${(props) => (props.isMobile ? '140%' : '170%')};
`

const NameContainer = styled(ColumnFlex)`
  align-items: start;
`

// eslint-disable-next-line @typescript-eslint/ban-types
interface SearchItemProps<T extends object> {
  item: SearchTreeItem<T>
  onSelect(): void
  iconProvider?(item: SearchTreeItem<T>): JSX.Element | string
  useTracking?: boolean
  renderItem?(
    item: SearchTreeItem<T>,
    onSelect: () => void,
    attr?: React.ButtonHTMLAttributes<HTMLButtonElement>,
    dataset?: Record<string, string>
  ): JSX.Element
}

const DescriptionTooltip: React.FC<
  React.PropsWithChildren<{ description?: string; descriptionTitle?: string }>
> = ({ description, descriptionTitle }) => {
  if (!description) {
    return null
  }
  return (
    <TooltipContainer>
      {descriptionTitle && <Typography style={{ fontSize: '17px' }}>{descriptionTitle}</Typography>}
      <Typography variant="body1">{description}</Typography>
    </TooltipContainer>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SearchItem = <T extends object>({
  item,
  onSelect,
  iconProvider,
  useTracking,
  renderItem,
}: SearchItemProps<T>) => {
  const { t } = useTranslation()
  const hasTooltip = (!item.children || item.children.length === 0) && Boolean(item.description)
  const isMobile = useIsMobile()

  if (renderItem) {
    return renderItem(
      item,
      onSelect,
      {
        'aria-expanded': item.children && item.children.length > 0 ? false : undefined,
        tabIndex: 0,
      },
      {
        'data-cy': 'searchItem',
        ...(useTracking ? { 'data-analytics-path': item.name } : {}),
      }
    )
  } else {
    return (
      <SearchItemButton
        aria-expanded={item.children && item.children.length > 0 ? false : undefined}
        onClick={onSelect}
        data-cy="searchItem"
        role="listitem"
        {...(useTracking ? { 'data-analytics-path': item.name } : {})}
        tabIndex={hasTooltip ? -1 : 0}
      >
        <ComponentWithIcon
          icon={iconProvider?.(item) ?? ''}
          size="24px"
          tooltip={
            hasTooltip ? (
              <DescriptionTooltip
                description={item.description}
                descriptionTitle={item.descriptionTitle}
              />
            ) : null
          }
          tooltipLabel={t('component.searchItem.tooltipLabel', { itemName: item.name })}
        >
          <Container>
            <NameContainer tabIndex={hasTooltip ? 0 : -1}>
              <div>
                <SearchItemName isMobile={isMobile}>{item.name}</SearchItemName>
              </div>
              {item.caption && <Caption isMobile={isMobile}>{item.caption}</Caption>}
            </NameContainer>
            {item.subtitle && <Subtitle>{item.subtitle}</Subtitle>}
          </Container>
        </ComponentWithIcon>
        {item.children && item.children.length > 0 && <AngleRight />}
      </SearchItemButton>
    )
  }
}

export const SearchItemsContainer = styled(ColumnFlex)``

// eslint-disable-next-line @typescript-eslint/ban-types
const groupItems = <T extends object>(items: Array<SearchTreeItem<T>>, noGroupId: string) =>
  items.reduce<Record<string, Array<SearchTreeItem<T>>>>((acc, cur) => {
    const index = cur.group ?? noGroupId
    if (!acc[index]) {
      acc[index] = []
    }
    acc[index].push(cur)
    return acc
  }, {})

// eslint-disable-next-line @typescript-eslint/ban-types
interface Props<T extends object> {
  items: Array<SearchTreeItem<T>>
  onSelect(item: SearchTreeItem<T>): void
  iconProvider?(item: SearchTreeItem<T>): JSX.Element | string
  parentName?: string
  useTracking?: boolean
  renderItem?(
    item: SearchTreeItem<T>,
    onSelect: () => void,
    attr?: React.ButtonHTMLAttributes<HTMLButtonElement>,
    dataset?: Record<string, string>
  ): JSX.Element
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SearchItems = <T extends object>({
  items,
  onSelect,
  iconProvider,
  parentName,
  useTracking,
  renderItem,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation()

  const noGroupId = t('component.search.group.none')
  const itemsGrouped = groupItems(items, noGroupId)
  const groupKeys = Object.keys(itemsGrouped)
  const showGroups =
    groupKeys.length > 1 || (groupKeys.length === 1 && !groupKeys.includes(noGroupId))

  return (
    <SearchItemsContainer>
      {Object.entries(itemsGrouped).map(([group, groupedItems]) => (
        <SearchItemGroup
          key={`search-item-group-${group}`}
          role="list"
          {...(useTracking
            ? {
                'data-analytics-path': parentName
                  ? `search_speciality_shortcut_link/${parentName}`
                  : 'search_speciality_shortcut_link',
              }
            : {})}
        >
          {showGroups && group !== noGroupId && (
            <SearchItemGroupTitle>{group}</SearchItemGroupTitle>
          )}
          {groupedItems.map((item) => (
            <SearchItem
              key={`searchItem-${item.id}`}
              item={item}
              onSelect={() => onSelect(item)}
              iconProvider={iconProvider}
              useTracking={useTracking}
              renderItem={renderItem}
            />
          ))}
        </SearchItemGroup>
      ))}
    </SearchItemsContainer>
  )
}

export default SearchItems
