import styled from '@emotion/styled'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LocationResult, LocationType, PractitionerDetails } from '../../../__generated__/api'
import { scale } from '../../utils/scale'
import ModalHeader from '../Modal/ModalHeader/ModalHeader'
import ResponsiveModal from '../Modal/ResponsiveModal/ResponsiveModal'
import SearchItems from '../Search/SearchItems'
import SelectInput from '../SelectInput/SelectInput'
import { Text } from '../Typography/Typography'

const Heading = styled(Text)`
  margin-bottom: ${scale(2)};
`
Heading.defaultProps = { as: 'h2', $size: 400, $weight: 'Regular', $height: 'Medium' }

interface Props {
  practitioner: PractitionerDetails
  allLocations: LocationResult[]
  value?: string
  onSelect(locationId?: string): void
}

const PractitionerLocationSelect: React.FC<React.PropsWithChildren<Props>> = ({
  practitioner,
  allLocations,
  value,
  onSelect,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const practitionerLocationIds = useMemo(
    () => practitioner.nearFutureLocations.map((location) => location.id),
    [practitioner.nearFutureLocations]
  )

  const selectableLocations = useMemo(
    () => [
      {
        id: 'all-locations',
        name: t('component.practitionerLocationSelect.allSelected'),
      },
      ...allLocations
        .filter(
          (location) =>
            location.type === LocationType.Clinic &&
            practitionerLocationIds.some(
              (practitionerLocationId) =>
                location.clinicIDs.includes(practitionerLocationId) ||
                location.assisDentBranchIDs.includes(practitionerLocationId)
            )
        )
        .map((location) => ({
          id: location.uniqueId,
          name: location.name,
          item: location,
        })),
    ],
    [t, allLocations, practitionerLocationIds]
  )

  const inputValue = useMemo(
    () =>
      practitioner.nearFutureLocations.length === 1
        ? practitioner.nearFutureLocations[0].name
        : selectableLocations.find((locationItem) => locationItem.id === value)?.name ??
          t('component.practitionerLocationSelect.allSelected'),
    [t, value, selectableLocations, practitioner.nearFutureLocations]
  )

  return (
    <>
      <SelectInput
        label={t('component.practitionerLocationSelect.label')}
        onClick={() => setOpen(true)}
        value={inputValue}
        dataCy="practitionerLocationSelect"
        disabled={practitioner.nearFutureLocations.length === 1}
      />
      <ResponsiveModal open={open} onClose={() => setOpen(false)} fullWidth>
        <ModalHeader onClose={() => setOpen(false)} />
        <Heading>{t('component.practitionerLocationSelect.heading')}</Heading>
        <SearchItems
          items={selectableLocations}
          onSelect={(searchTreeItem) => {
            onSelect(searchTreeItem.item?.uniqueId)
            setOpen(false)
          }}
          iconProvider={() => `${process.env.REACT_APP_NODE_ICON_URL}mehilainen.svg`}
        />
      </ResponsiveModal>
    </>
  )
}

export default PractitionerLocationSelect
