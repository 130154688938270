import { AppointmentReservationInfo, AppointmentType } from '../../../__generated__/api'
import dayjs from '../../utils/dayjs/dayjs'
import { formatCallbackTimeRaw } from '../../utils/text'

const DATE_TIME_FORMAT = 'YYYYMMDDTHHmmss'
const DATE_FORMAT = 'YYYYMMDD'
const TZ_ID = 'TZID=Europe/Helsinki'

export const createCalendarEvent = (uid: string, appointment: AppointmentReservationInfo) => {
  const location =
    appointment.type === AppointmentType.Clinic ? `LOCATION:${appointment.locationAddress}%0A` : ''

  let dstart = ''
  let dend = ''
  if (appointment.type === AppointmentType.Callback) {
    const formattedTime = formatCallbackTimeRaw(dayjs(appointment.time))
    if (formattedTime) {
      dstart = `DTSTART;${TZ_ID}:${formattedTime.start.format(DATE_TIME_FORMAT)}%0A`
      dend = `DTEND;${TZ_ID}:${formattedTime.end.format(DATE_TIME_FORMAT)}%0A`
    } else {
      // Full day event
      dstart = `DTSTART;VALUE=DATE:${dayjs(appointment.time).format(DATE_FORMAT)}%0A`
    }
  } else {
    dstart = `DTSTART;${TZ_ID}:${dayjs(appointment.time).format(DATE_TIME_FORMAT)}%0A`
    dend = `DTEND;${TZ_ID}:${dayjs(appointment.time)
      .add(appointment.duration, 'minutes')
      .format(DATE_TIME_FORMAT)}%0A`
  }

  const icsData =
    `BEGIN:VCALENDAR%0A` +
    `VERSION:2.0%0A` +
    `METHOD:PUBLISH%0A` +
    `PRODID:-//hacksw/handcal//NONSGML v1.0//EN%0A` +
    `BEGIN:VEVENT%0A` +
    `UID:${uid}%0A` +
    `DTSTAMP:${dayjs().format(DATE_TIME_FORMAT)}%0A` +
    dstart +
    dend +
    `SUMMARY:Mehiläinen ajanvaraus%0A` +
    `CLASS:PRIVATE%0A` +
    `STATUS:CONFIRMED%0A` +
    location +
    `END:VEVENT%0A` +
    `END:VCALENDAR%0A`

  window.open(`data:text/calendar;charset=utf8,${icsData}`)
}
