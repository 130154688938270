import styled from '@emotion/styled'
import {
  Attention400,
  Gray300,
  Gray600,
  Gray900,
  White,
} from '@mehilainen/design-system-tokens/colors'
import { ExclamationCircle } from '@mehilainen/mds-customer/icons'
import React, { useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import Avatar, { resourceListImage } from '../../../common/components/Avatar/Avatar'
import { DefaultButton } from '../../../common/components/DefaultButton/DefaultButton'
import { CenteredColumnFlex, CenteredRowFlex } from '../../../common/components/Layout/Layout'
import NavBar from '../../../common/components/NavBar/NavBar'
import useChangeLanguage from '../../../common/hooks/useChangeLanguage'
import useLoginState from '../../../common/hooks/useLoginState'
import { useNode } from '../../../common/hooks/useNode'
import useTranslation from '../../../common/hooks/useTranslation'
import { breakpoint } from '../../../common/utils/breakpoint'
import dayjs from '../../../common/utils/dayjs/dayjs'
import { scale } from '../../../common/utils/scale'
import { formatDateAndTime2 } from '../../../common/utils/text'
import {
  generalErrorMessageAtom,
  isFromAppAtom,
  selectedAppointmentNodeIdAtom,
} from '../../../state/common/atoms'
import { selectedAppointmentDetailsAtom } from '../../../state/reserve/atoms'

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 10px;
`

const HeadingContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`

const StyledExclamationCircle = styled(ExclamationCircle)`
  margin: 18px;
  color: ${Attention400};
  width: 46px;
  height: 46px;
`

const StyledH1 = styled.h1`
  font-size: 26px;
  text-align: center;
`
const StyledH2 = styled.h2`
  font-size: 16px;
  text-align: center;
`

const AppointmentContainer = styled(CenteredColumnFlex)`
  position: relative;
  overflow: hidden;
  padding: 32px 24px 24px 24px;
  gap: ${scale(2)};
  background: ${Gray300};
  border-radius: 8px;
`

const AppointmentDetailsTitle = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px 12px 6px 12px;
  color: ${White};
  background: ${Gray600};
  border-radius: 0 0 8px 0;
`

const AppointmentDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;
  padding: 20px;
  opacity: 70%;
  margin: 0 auto;
  @media (max-width: ${breakpoint.sm}px) {
    flex-direction: column;
  }
`

const PractitionerContainer = styled(CenteredRowFlex)`
  gap: 20px;
  align-items: flex-start;
`

const StyledText = styled.span`
  display: block;
  font-size: 14px;
  color: ${Gray900};
`

const BoldText = styled(StyledText)`
  font-weight: 450;
`

interface Props {
  onBackClick: () => void
}

const AppointmentNotFoundView: React.FC<Props> = ({ onBackClick }) => {
  const { t } = useTranslation()
  const isFromApp = useRecoilValue(isFromAppAtom)
  const setGeneralErrorMessage = useSetRecoilState(generalErrorMessageAtom)
  const appointmentInfo = useRecoilValue(selectedAppointmentDetailsAtom)

  const sessionExpiredCallback = useCallback(
    () => setGeneralErrorMessage('common.sessionExpired'),
    [setGeneralErrorMessage]
  )
  const { loginStatus, logout } = useLoginState(sessionExpiredCallback)
  const { changeLanguage } = useChangeLanguage()
  const selectedAppointmentNodeId = useRecoilValue(selectedAppointmentNodeIdAtom)
  const { node, pending: nodePending } = useNode(selectedAppointmentNodeId)

  return (
    <Container>
      {!isFromApp && (
        <NavBar
          onLanguageSelect={changeLanguage}
          loginStatus={loginStatus}
          logoutCallBack={logout}
          variant="detached"
        />
      )}
      <HeadingContainer>
        <StyledExclamationCircle />
        <StyledH1>{t('component.appointmentNotFound.title')}</StyledH1>
        <StyledH2>{t('component.appointmentNotFound.description')}</StyledH2>
      </HeadingContainer>
      {appointmentInfo && (
        <AppointmentContainer>
          <AppointmentDetailsTitle>
            {t('component.appointmentNotFound.cardTitle')}
          </AppointmentDetailsTitle>
          <AppointmentDetailsContainer>
            <div>
              <BoldText>{formatDateAndTime2(dayjs(appointmentInfo.time))}</BoldText>
              <StyledText>{appointmentInfo.locationName}</StyledText>
              {node && !nodePending && <StyledText>{node.name}</StyledText>}
            </div>
            <PractitionerContainer>
              <Avatar
                width={'50px'}
                height={'62px'}
                ratingFontSize="11px"
                ratingPadding="4px"
                borderRadius="10px"
                image={appointmentInfo.isResourceList ? resourceListImage : appointmentInfo.image}
                tabIndex={0}
              />
              <div>
                <BoldText>{appointmentInfo.specialistName}</BoldText>
                <StyledText>{appointmentInfo.specialistTitle}</StyledText>
              </div>
            </PractitionerContainer>
          </AppointmentDetailsContainer>
        </AppointmentContainer>
      )}
      <DefaultButton onClick={() => onBackClick()}>
        {t('component.appointmentNotFound.backButton')}
      </DefaultButton>
    </Container>
  )
}

export default AppointmentNotFoundView
