import styled from '@emotion/styled'
import { Alert } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import BookingConfirmation from '../../../common/components/BookingConfirmation/BookingConfirmation'
import { ColumnFlex } from '../../../common/components/Layout/Layout'
import { useApi } from '../../../common/hooks/useApi'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import useMobileConfirmation from '../../../common/hooks/useMobileConfirmation'
import api from '../../../common/services/api'
import * as Analytics from '../../../common/utils/analytics'
import { scale } from '../../../common/utils/scale'
import {
  isOHCAtom,
  selectedAppointmentLengthAtom,
  selectedAppointmentNodeIdAtom,
  selectedAppointmentServiceIdAtom,
} from '../../../state/common/atoms'
import { practitionerModalPractitionerId } from '../../../state/practitioner/atoms'
import { bookedForSelfAtom } from '../../../state/reserve/atoms'
import {
  reservationConfirmationContactByPhoneAtom,
  reservationConfirmationEmailAtom,
} from '../../../state/user/atoms'

const Container = styled(ColumnFlex)<{ isMobile: boolean }>`
  padding-top: ${(props) => (props.isMobile ? '0px' : '80px')};
  min-height: 100%;
  margin-bottom: ${scale(12)};
`

interface Props {
  onBackToStart(): void
}

const BookingConfirmationView: React.VFC<Props> = ({ onBackToStart }) => {
  const { reservationId, appointmentId } = useParams<{
    reservationId: string
    appointmentId: string
  }>()
  const { i18n, t } = useTranslation()
  const confirmationEmail = useRecoilValue(reservationConfirmationEmailAtom)
  const confirmationContactByPhone = useRecoilValue(reservationConfirmationContactByPhoneAtom)
  const setPractitionerModalPractitionerId = useSetRecoilState(practitionerModalPractitionerId)
  const isMobile = useIsMobile()
  const selectedAppointmentLength = useRecoilValue(selectedAppointmentLengthAtom)
  const bookedForSelf = useRecoilValue(bookedForSelfAtom)
  const selectedAppointmentNodeId = useRecoilValue(selectedAppointmentNodeIdAtom)
  const selectedAppointmentServiceId = useRecoilValue(selectedAppointmentServiceIdAtom)
  useMobileConfirmation()
  const isOHC = useRecoilValue(isOHCAtom)
  const {
    data: appointmentInfo,
    error: appointmentError,
    pending: appointmentPending,
  } = useApi(
    api.v1.reservationInfo,
    { reservationId, appointmentId: parseInt(appointmentId, 10), lang: i18n.language },
    null
  )

  const { data: bookingInstructions } = useApi(
    api.v1.getBookingInstructions,
    {
      lang: i18n.language,
      nodeId: selectedAppointmentNodeId,
      serviceId: selectedAppointmentServiceId,
    },
    null,
    Boolean(selectedAppointmentNodeId || selectedAppointmentServiceId)
  )

  const getErrorMessage = (statusCode: number | undefined) => {
    switch (statusCode) {
      case 404:
        return t('component.bookingConfirmed.notFound')
      case 410:
        return t('component.bookingConfirmed.expired')
      default:
        return t('component.bookingConfirmed.notFound')
    }
  }

  Analytics.useTrackConfirm({
    appointmentInfo,
    bookedFor: bookedForSelf ? 'Myself' : 'Other',
    duration: selectedAppointmentLength === 'default' ? 'regular' : 'extended',
    isOHC,
  })

  return (
    <>
      {appointmentPending ? (
        <Container isMobile={isMobile} />
      ) : appointmentError ? (
        <Container isMobile={isMobile} data-cy="bookingConfirmationErrorContainer">
          <Alert severity="error">{getErrorMessage(appointmentError.statusCode)}</Alert>
        </Container>
      ) : appointmentInfo ? (
        <Container isMobile={isMobile}>
          <BookingConfirmation
            uid={reservationId}
            appointment={appointmentInfo}
            confirmationEmail={confirmationEmail}
            confirmationContactByPhone={confirmationContactByPhone}
            bookingInstructions={bookingInstructions}
            onBackToStart={onBackToStart}
            onPractitionerClicked={(practitionerId) =>
              setPractitionerModalPractitionerId(practitionerId)
            }
          />
        </Container>
      ) : null}
    </>
  )
}

export default BookingConfirmationView
