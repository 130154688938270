import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { SupportedLanguage } from '../../../__generated__/api'
import type { BreadcrumbPath } from '../../../common/components/Breadcrumb/Breadcrumb'
import { useNodes } from '../../../common/hooks/useNode'
import api from '../../../common/services/api'
import { isOHCAtom } from '../../../state/common/atoms'
import {
  AppointmentSearchMode,
  appointmentSearchModeAtom,
  selectedFlexibleScheduleOptionAtom,
  selectedSearchPractitionerAtom,
  selectedStandardScheduleOptionAtom,
} from '../../../state/search/atoms'
import { getDefaultNode, getSelectedNodeOrDefault } from '../../../state/search/selectors'

export enum SearchTargetValue {
  Node = 'node',
  Practitioner = 'practitioner',
}

export type SearchTarget =
  | { id: string; value: SearchTargetValue.Node }
  | { id: number; value: SearchTargetValue.Practitioner }

type SearchTargetContextType = {
  setSearchTarget(target: SearchTarget, fromNode?: string): Promise<void>
  searchTarget: SearchTarget
  breadcrumb: BreadcrumbPath[]
}

const SearchTargetContext = createContext<SearchTargetContextType>({
  setSearchTarget: () => {
    return Promise.resolve()
  },
  searchTarget: { id: '', value: SearchTargetValue.Node },
  breadcrumb: [],
})

export const SearchTargetProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { t, i18n } = useTranslation()
  const { getNodeById } = useNodes()
  const [selectedNodeId, setSelectedNodeId] = useRecoilState(getSelectedNodeOrDefault)
  const [selectedPractitioner, setSelectedPractitioner] = useRecoilState(
    selectedSearchPractitionerAtom
  )
  const setSelectedFlexibleScheduleOption = useSetRecoilState(selectedFlexibleScheduleOptionAtom)
  const setSelectedStandardScheduleOption = useSetRecoilState(selectedStandardScheduleOptionAtom)
  const setAppointmentSearchMode = useSetRecoilState(appointmentSearchModeAtom)

  const defaultNode = useRecoilValue(getDefaultNode)
  const isOhc = useRecoilValue(isOHCAtom)
  const breadcrumbHome = useMemo<BreadcrumbPath>(
    () => ({
      name: isOhc ? t('common.OHCHome') : t('common.home'),
      searchTarget: {
        id: defaultNode,
        value: SearchTargetValue.Node,
      },
    }),
    [defaultNode, isOhc, t]
  )

  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbPath[]>([])

  const setSearchTarget = useCallback(
    async (target: SearchTarget, fromNode?: string) => {
      setSelectedFlexibleScheduleOption(undefined)
      setSelectedStandardScheduleOption('default')

      if (target.value === SearchTargetValue.Node) {
        const foundNode = getNodeById(target.id)
        setSelectedNodeId(target.id)
        setSelectedPractitioner(undefined)
        setBreadcrumb([
          {
            name: foundNode?.node?.name ?? '',
            searchTarget: { id: target.id, value: SearchTargetValue.Node },
          },
        ])
        setAppointmentSearchMode(AppointmentSearchMode.REGULAR)
        return
      }

      if (target.value === SearchTargetValue.Practitioner) {
        const practitionerDetailsResponse = await api.v1.getPractitionerDetails({
          lang: i18n.language as SupportedLanguage,
          practitionerId: target.id,
          isOhc: isOhc,
        })
        setSelectedPractitioner(practitionerDetailsResponse.data)

        if (fromNode) {
          const foundNode = getNodeById(fromNode)
          setBreadcrumb([
            {
              name: foundNode?.node?.name ?? '',
              searchTarget: { id: fromNode, value: SearchTargetValue.Node },
            },
            {
              name: practitionerDetailsResponse.data.name,
              searchTarget: { id: target.id, value: SearchTargetValue.Practitioner },
            },
          ])
        } else {
          setBreadcrumb([
            {
              name: practitionerDetailsResponse.data.name,
              searchTarget: { id: target.id, value: SearchTargetValue.Practitioner },
            },
          ])
        }
      }
    },
    [
      i18n.language,
      isOhc,
      setSelectedFlexibleScheduleOption,
      setSelectedNodeId,
      setSelectedPractitioner,
      setSelectedStandardScheduleOption,
      setAppointmentSearchMode,
      getNodeById,
    ]
  )

  const searchTarget: SearchTarget = useMemo(() => {
    if (selectedPractitioner) {
      return { id: selectedPractitioner.id, value: SearchTargetValue.Practitioner }
    }

    return { id: selectedNodeId, value: SearchTargetValue.Node }
  }, [selectedNodeId, selectedPractitioner])

  const memoized = useMemo(
    () => ({
      setSearchTarget,
      searchTarget,
      breadcrumb: [breadcrumbHome, ...breadcrumb],
    }),
    [setSearchTarget, searchTarget, breadcrumbHome, breadcrumb]
  )

  return <SearchTargetContext.Provider value={memoized}>{children}</SearchTargetContext.Provider>
}

const useSearchTarget = () => {
  return useContext(SearchTargetContext)
}

export default useSearchTarget
