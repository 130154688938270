import { SearchNode } from '../../../__generated__/api'

import { SearchTreeItem } from './types'

export const convertSearchNode = (
  node: SearchNode,
  secondaryGroupLabel?: string,
  group?: string
): SearchTreeItem<SearchNode> => ({
  id: node.id,
  name: node.name,
  item: node,
  children: node.children ? convertSearchNodes(node.children, secondaryGroupLabel) : undefined,
  parentName: node.parentName,
  description: node.description,
  descriptionTitle: node.descriptionTitle,
  caption: node.caption,
  group: node.priority === 2 ? secondaryGroupLabel : group,
})

export const convertSearchNodes = (
  nodes: SearchNode[],
  secondaryGroupLabel?: string,
  group?: string
): Array<SearchTreeItem<SearchNode>> =>
  nodes.map((node) => convertSearchNode(node, secondaryGroupLabel, group))

export const searchTreeItemIconProvider = (item: SearchTreeItem<SearchNode>) =>
  item.item?.icon ? item.item.icon : `${process.env.REACT_APP_NODE_ICON_URL}mehilainen.svg`
