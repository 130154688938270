import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Gray600,
  White,
  Primary600,
  Primary700,
  Gray900,
} from '@mehilainen/design-system-tokens/colors'
import { AngleRight } from '@mehilainen/mds-customer/icons'
import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { useIsMobile } from '../../hooks/useBreakpoint'
import { scale } from '../../utils/scale'
import { ColumnFlex, VisuallyHidden } from '../Layout/Layout'

const LabelContainer = styled(ColumnFlex)`
  gap: ${scale(0.5)};
`

const StyledLabel = styled.label`
  color: ${Gray600};
  text-align: left;
`

const StyledButton = styled.button<{ $mobile: boolean; disabled: boolean }>`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  border: none;
  border-radius: ${scale(0.5)};
  background: ${White};
  padding: ${scale(1.5)} ${scale(2)};
  border: 2px solid ${White};
  color: ${Gray900};

  span {
    text-align: start;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: ${(props) => (props.$mobile ? 'wrap' : 'nowrap')};
    color: ${Primary600};
    font-size: ${scale(2)};
    font-weight: 500;
  }

  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;

      ${StyledLabel} {
        cursor: pointer;
      }

      span {
        cursor: pointer;
      }

      &:hover {
        border-color: ${Primary700};
      }
    `}

  ${StyledLabel} {
    font-size: ${(props) => (props.$mobile ? scale(1.5) : scale(1.75))};
  }
`

interface Props {
  label: string
  disabled?: boolean
  value?: string | null
  dataCy?: string
  onClick(): void
}

const SelectInput: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  value,
  disabled,
  dataCy,
  onClick,
}) => {
  const buttonId = useMemo(() => `selectInput-${uuid()}`, [])
  const isMobile = useIsMobile()

  return (
    <StyledButton
      id={buttonId}
      onClick={onClick}
      data-cy={dataCy}
      $mobile={isMobile}
      disabled={Boolean(disabled)}
    >
      <LabelContainer>
        <StyledLabel htmlFor={buttonId}>
          {label}
          {value && <VisuallyHidden>, {value}</VisuallyHidden>}
        </StyledLabel>
        {value && <span>{value}</span>}
      </LabelContainer>
      {!disabled && <AngleRight />}
    </StyledButton>
  )
}

export default SelectInput
