import styled from '@emotion/styled'
import { White } from '@mehilainen/design-system-tokens/colors'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import { LocationResult, SearchNode } from '../../../__generated__/api'
import AppointmentListToggle, {
  AppointmentListVariant,
} from '../../../common/components/AppointmentList/AppointmentListToggle'
import Breadcrumb from '../../../common/components/Breadcrumb/Breadcrumb'
import { MinMaxDiv, VisuallyHidden } from '../../../common/components/Layout/Layout'
import { Text } from '../../../common/components/Typography/Typography'
import { useIsDesktop } from '../../../common/hooks/useBreakpoint'
import { useOHC } from '../../../common/hooks/useOHC'
import { maxWidth } from '../../../common/utils/breakpoint'
import { isFromAppAtom } from '../../../state/common/atoms'
import { selectedSearchPractitionerAtom } from '../../../state/search/atoms'
import useSearchTarget, { SearchTargetValue } from '../hooks/useSearchTarget'

import SearchFilterChips from './SearchFilterChips'
import SearchLocationSelect from './SearchLocationSelect'
import SearchPractitionerServiceSelect from './SearchPractitionerServiceSelect'
import SearchServiceSelect from './SearchServiceSelect'
import { PatternContainer, ResponsiveContainer } from './SearchViewContainers'

interface Props {
  selectedNode: SearchNode | null
  locations: LocationResult[]
  appointmentListVariant: AppointmentListVariant
  setAppointmentListVariant(variant: AppointmentListVariant): void
}

const BreadcrumbContainer = styled.div`
  margin-top: 16px;
  margin-bottom: -8px;
`

const SearchHeader = React.forwardRef<HTMLDivElement, Props>(
  ({ selectedNode, locations, appointmentListVariant, setAppointmentListVariant }, ref) => {
    const isDesktop = useIsDesktop()
    const isFromApp = useRecoilValue(isFromAppAtom)
    const { searchTarget, setSearchTarget, breadcrumb } = useSearchTarget()
    const selectedPractitioner = useRecoilValue(selectedSearchPractitionerAtom)
    const { t } = useTranslation()
    const { isOHCSide } = useOHC()

    const breadcrumbPath = useMemo(() => {
      if (breadcrumb.length <= 2) {
        return [breadcrumb[0]]
      } else {
        return breadcrumb.slice(0, 2)
      }
    }, [breadcrumb])

    return (
      <>
        <PatternContainer ref={ref} isFromApp={isFromApp}>
          <MinMaxDiv size={`${maxWidth.content}px`} gap={!isDesktop ? '16px' : undefined}>
            <VisuallyHidden>
              <Text as="h1" $size={450} $color={White}>
                {t('component.searchView.heading')}
              </Text>
            </VisuallyHidden>
            <BreadcrumbContainer>
              <Breadcrumb
                path={breadcrumbPath}
                color={White}
                onSelect={(selectedPath) => setSearchTarget(selectedPath.searchTarget)}
              />
            </BreadcrumbContainer>
            <ResponsiveContainer>
              <SearchServiceSelect />
              {selectedPractitioner && (
                <SearchPractitionerServiceSelect practitionerDetails={selectedPractitioner} />
              )}
              <SearchLocationSelect
                locations={locations}
                selectedPractitioner={selectedPractitioner}
                selectedNode={selectedNode}
              />
            </ResponsiveContainer>
            <SearchFilterChips hideInsuranceButton={isOHCSide} />
          </MinMaxDiv>
        </PatternContainer>
        {searchTarget.value === SearchTargetValue.Node && (
          <MinMaxDiv size={`${maxWidth.content}px`}>
            <AppointmentListToggle
              value={appointmentListVariant}
              onToggle={setAppointmentListVariant}
            />
          </MinMaxDiv>
        )}
      </>
    )
  }
)

export default SearchHeader
