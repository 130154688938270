import styled from '@emotion/styled'
import {
  Gray200,
  Gray300,
  Gray900,
  Primary300,
  Primary500,
} from '@mehilainen/design-system-tokens/colors'
import { AngleRight, Tooth, Stethoscope, Briefcase, Headset } from '@mehilainen/mds-customer/icons'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { SupportedLanguage } from '../../../__generated__/api'
import { insuranceModalOpenAtom, isFromAppAtom, isOHCAtom } from '../../../state/common/atoms'
import { callbackModalOpenAtom } from '../../../state/search/atoms'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { getCustomerServiceNumber } from '../../services/customerService'
import * as Analytics from '../../utils/analytics'
import LangSelectButton from '../LangSelectButton/LangSelectButton'
import ResponsiveModal from '../Modal/ResponsiveModal/ResponsiveModal'
import DesktopLogo from '../NavBar/desktopLogo.svg?react'
import { Text } from '../Typography/Typography'

interface NavLinkProps {
  text: string
  icon: JSX.Element
  onClick?: () => void
}

const StyledButton = styled.button`
  display: flex;
  gap: 17px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 20px;
  background: white;
  border-radius: 4px;
  border: 1px solid ${Gray300};
  font-size: 16px;
  align-items: center;

  &:hover {
    background: ${Gray200};
  }

  .MuiSvgIcon-root {
    color: ${Primary500};
  }
`

const StyledAngleRight = styled(AngleRight)`
  margin-left: auto;
`

const NavLink: React.FC<React.PropsWithChildren<NavLinkProps>> = ({ text, onClick, icon }) => {
  return (
    <StyledButton onClick={onClick}>
      {icon}
      <Text $size={400} $weight="Regular" $height="Medium" $color={Gray900}>
        {text}
      </Text>
      <StyledAngleRight />
    </StyledButton>
  )
}

const TopContainer = styled.div`
  display: flex;
`

const LogoContainer = styled.div`
  margin-bottom: 30px;
`

const StyledLangSelectButton = styled(LangSelectButton)`
  margin-left: auto;
  margin-top: 3px;
`

const StyledH1 = styled.h1`
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
`

const StyledH2 = styled.h2`
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 30px;
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`

const StyledH3 = styled.h3`
  text-align: center;
  font-size: 14px;
`

const StyledText = styled(Text)`
  text-align: center;
  font-size: 24px;
  text-decoration: none;
  &:not(.no-link-styles) {
    &:link {
      text-decoration: none;
    }
  }
`

const sessionStorageKey = 'landingModalShown'

const isServiceSelectionType = (value: string): value is Analytics.ServiceSelectionType =>
  Object.values(Analytics.ServiceSelectionType).includes(value as Analytics.ServiceSelectionType)

interface Props {
  onLanguageSelect(lang: SupportedLanguage): void
}

const LandingModal: React.FC<React.PropsWithChildren<Props>> = ({ onLanguageSelect }) => {
  const { t, i18n } = useTranslation()
  const isFromApp = useRecoilValue(isFromAppAtom)
  const isOHC = useRecoilValue(isOHCAtom)
  const isMobile = useIsMobile()
  const [open, setOpen] = React.useState(true)
  const setIsInsuranceModalOpen = useSetRecoilState(insuranceModalOpenAtom)
  const setCallbackModalOpen = useSetRecoilState(callbackModalOpenAtom)
  const link = getCustomerServiceNumber('general', i18n.language)
  const { label: telNumber } = link
  const location = useLocation()
  const featureFlags = useFeatureFlags()
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    if (searchParams.has('noLanding')) {
      const noLandingValue = searchParams.get('noLanding') ?? ''
      if (isServiceSelectionType(noLandingValue)) {
        Analytics.trackServiceSelection(noLandingValue)
      }
    }

    if (
      isFromApp ||
      (window.sessionStorage && window.sessionStorage.getItem(sessionStorageKey)) ||
      searchParams.has('noLanding') ||
      isOHC
    ) {
      setOpen(false)
    }
  }, [isFromApp, isOHC, location.search])

  const close = (openInsurance?: boolean) => {
    window.sessionStorage.setItem(sessionStorageKey, 'true')
    setOpen(false)

    if (openInsurance === true) {
      setIsInsuranceModalOpen(true)
    }
  }

  const openCallbackReservation = () => {
    Analytics.trackServiceSelection(Analytics.ServiceSelectionType.CALL)

    if (featureFlags?.callbackRequestsEnabled) {
      setCallbackModalOpen(true)
    } else {
      window.location.assign(t('component.landingModal.callbackLink'))
    }

    close()
  }

  // prevents modal blocking scroll https://porriainen.atlassian.net/browse/AV3-1661
  if (!open) {
    return null
  }

  return (
    <ResponsiveModal open={true} fullWidth maxWidth="md">
      {isMobile ? (
        <>
          <TopContainer>
            <LogoContainer>
              <DesktopLogo />
            </LogoContainer>
            <StyledLangSelectButton onLanguageSelect={onLanguageSelect} />
          </TopContainer>
          <StyledH1>{t('component.landingModal.heading')}</StyledH1>
        </>
      ) : (
        <TopContainer>
          <StyledH1>{t('component.landingModal.heading')}</StyledH1>
          <StyledLangSelectButton onLanguageSelect={onLanguageSelect} />
        </TopContainer>
      )}

      <NavLink
        text={t('component.landingModal.privateLinkText')}
        onClick={() => {
          Analytics.trackServiceSelection(Analytics.ServiceSelectionType.PRIVATE)
          close()
        }}
        icon={<Stethoscope />}
      />
      <NavLink
        text={t('component.landingModal.insuranceLinkText')}
        onClick={() => {
          Analytics.trackServiceSelection(Analytics.ServiceSelectionType.INSURANCE)
          close(true)
        }}
        icon={<Stethoscope />}
      />
      <NavLink
        text={t('component.landingModal.dentalLinkText')}
        onClick={() => {
          Analytics.trackServiceSelection(Analytics.ServiceSelectionType.DENTAL)
          window.location.assign(t('component.landingModal.dentalLink'))
        }}
        icon={<Tooth />}
      />
      <NavLink
        text={t('component.landingModal.occupationalLinkText')}
        onClick={() => {
          Analytics.trackServiceSelection(Analytics.ServiceSelectionType.OCCUPATIONAL)
          window.location.assign(t('component.landingModal.occupationalLink'))
        }}
        icon={<Briefcase />}
      />
      <StyledH2>{t('component.landingModal.callbackHeading')}</StyledH2>
      <NavLink
        text={t('component.landingModal.callbackLinkText')}
        onClick={openCallbackReservation}
        icon={<Headset />}
      />
      <ContactContainer>
        <StyledH3>{t('component.landingModal.contactHeading')}</StyledH3>
        <StyledText as="a" $color={Primary300} target="_blank" href={link.link}>
          {telNumber}
        </StyledText>
        <StyledH3>{t('component.landingModal.customerServicePhonePrice')}</StyledH3>
      </ContactContainer>
    </ResponsiveModal>
  )
}

export default LandingModal
