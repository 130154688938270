import styled from '@emotion/styled'
import React from 'react'

import { ColumnFlex, MinMaxDiv } from '../Layout/Layout'

const Heading = styled.span`
  color: black;
  font-size: 26px;
  font-weight: 400;
  line-height: 140%;
`

const Container = styled(ColumnFlex)`
  background: white;
  border-radius: 12px;
  margin: 10px;
  padding: 32px 16px;
  align-items: center;
  text-align: center;

  > .MuiSvgIcon-root {
    color: black;
    margin-bottom: 16px;
    width: 40px;
    height: 40px;
  }

  ${Heading} {
    margin-bottom: 40px;
  }
`

interface Props {
  icon?: JSX.Element
  heading?: string
  className?: string
}

const Card: React.FC<React.PropsWithChildren<Props>> = ({ icon, heading, className, children }) => {
  return (
    <MinMaxDiv size="750px">
      <Container className={className}>
        {icon}
        {heading && <Heading tabIndex={0}>{heading}</Heading>}
        {children}
      </Container>
    </MinMaxDiv>
  )
}

export default Card
