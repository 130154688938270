import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useMemo } from 'react'

import {
  AppointmentSearchResult,
  DCNotification,
  NotificationPlacement,
  NotificationStyle,
  SimpleNotification,
} from '../../../__generated__/api'
import { useIsDesktop, useIsMobile } from '../../hooks/useBreakpoint'
import { breakpoint } from '../../utils/breakpoint'
import { scale } from '../../utils/scale'

import BaseTile from './BaseTile'
import NotificationTile, {
  isDCNotification,
  isSimpleNotification,
  NotificationMobileBaseTile,
} from './NotificationTile'
import SpecialistTile from './SpecialistTile'
import TimeslotTile, { MobileBaseTile } from './TimeslotTile'

const SpecialistTilesContainer = styled.ul<{ isDesktop: boolean }>`
  ${(props) =>
    props.isDesktop
      ? css`
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-row-gap: ${scale(0.5)};
          grid-column-gap: ${scale(0.5)};
        `
      : css`
          display: flex;
          flex-direction: column;
        `}

  @media (max-width: ${breakpoint.sm}px) {
    margin: 0 -${scale(2)};
    gap: ${scale(0.25)};
  }

  @media (min-width: ${breakpoint.sm}px) {
    gap: ${scale(0.5)};
  }
`

const TimeslotTilesContainer = styled.ul`
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoint.sm}px) {
    margin: 0 -${scale(2)};
    gap: ${scale(0.25)};
  }

  @media (min-width: ${breakpoint.sm}px) {
    gap: ${scale(0.5)};
  }

  ${MobileBaseTile} + ${NotificationMobileBaseTile} {
    margin-top: ${scale(1)};
  }

  ${NotificationMobileBaseTile} + ${MobileBaseTile} {
    margin-top: ${scale(1)};
  }
`

interface Props {
  appointments: AppointmentSearchResult[]
  variant: 'specialist' | 'timeslot'
  hideSpecialistDetails?: 'hide-both' | 'hide-mobile' | 'false'
  onSpecialistClick?(appointment: AppointmentSearchResult): void
  onAppointmentClick?(appointment: AppointmentSearchResult): void
  remoteSupported: boolean
  notifications: Array<DCNotification | SimpleNotification>
  onDcNotificationClick(queue: string): void
}

interface LoadingTilesProps extends Pick<Props, 'variant'> {
  numTiles?: number
}

export const LoadingTiles: React.FC<React.PropsWithChildren<LoadingTilesProps>> = ({
  variant,
  numTiles = 5,
}) => {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()

  if (variant === 'specialist') {
    return (
      <SpecialistTilesContainer isDesktop={isDesktop}>
        {Array(numTiles)
          .fill(0)
          .map((_, i) => (
            <BaseTile key={`loadingTile-${i}`} skeleton minHeight="105px" />
          ))}
      </SpecialistTilesContainer>
    )
  }

  return (
    <TimeslotTilesContainer>
      {Array(numTiles)
        .fill(0)
        .map((_, i) => (
          <BaseTile key={`loadingTile-${i}`} skeleton minHeight={isMobile ? '155px' : '95px'} />
        ))}
    </TimeslotTilesContainer>
  )
}

const Tiles: React.FC<React.PropsWithChildren<Props>> = ({
  appointments,
  variant,
  hideSpecialistDetails,
  onSpecialistClick,
  onAppointmentClick,
  remoteSupported,
  notifications,
  onDcNotificationClick,
}) => {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()

  const sortedNotifications = useMemo(
    () => [
      ...notifications.filter((notification) => isDCNotification(notification)),
      ...notifications.filter(
        (notification) =>
          isSimpleNotification(notification) && notification.style === NotificationStyle.WalkIn
      ),
      ...notifications.filter(
        (notification) =>
          isSimpleNotification(notification) && notification.style !== NotificationStyle.WalkIn
      ),
    ],
    [notifications]
  )

  if (variant === 'specialist') {
    return (
      <SpecialistTilesContainer isDesktop={isDesktop}>
        {appointments.map((appointment, idx) => (
          <SpecialistTile
            key={`specialistTile-${appointment.appointmentId}-${idx}`}
            variant={isMobile ? 'S' : isDesktop ? 'L' : 'M'}
            appointment={appointment}
            onClick={onAppointmentClick ? () => onAppointmentClick(appointment) : undefined}
            onSpecialistClick={onSpecialistClick ? () => onSpecialistClick(appointment) : undefined}
          />
        ))}
      </SpecialistTilesContainer>
    )
  }

  return (
    <TimeslotTilesContainer>
      {sortedNotifications
        .filter((notification) =>
          notification.placements.includes(NotificationPlacement.SearchResultsTop)
        )
        .map((notification) => (
          <NotificationTile
            key={`notification-${notification.id}`}
            notification={notification}
            onDCNotificationClick={onDcNotificationClick}
          />
        ))}
      {appointments.slice(0, 2).map((appointment) => (
        <TimeslotTile
          key={`timeslotTile-${appointment.appointmentId}`}
          appointment={appointment}
          hideSpecialistDetails={hideSpecialistDetails}
          onClick={onAppointmentClick ? () => onAppointmentClick(appointment) : undefined}
          onSpecialistClick={onSpecialistClick ? () => onSpecialistClick(appointment) : undefined}
          remoteSupported={remoteSupported}
        />
      ))}
      {sortedNotifications
        .filter((notification) =>
          notification.placements.includes(NotificationPlacement.SearchResultsWithin)
        )
        .map((notification) => (
          <NotificationTile
            key={`notification-${notification.id}`}
            notification={notification}
            onDCNotificationClick={onDcNotificationClick}
          />
        ))}
      {appointments.slice(2).map((appointment) => (
        <TimeslotTile
          key={`timeslotTile-${appointment.appointmentId}`}
          appointment={appointment}
          hideSpecialistDetails={hideSpecialistDetails}
          onClick={onAppointmentClick ? () => onAppointmentClick(appointment) : undefined}
          onSpecialistClick={onSpecialistClick ? () => onSpecialistClick(appointment) : undefined}
          remoteSupported={remoteSupported}
        />
      ))}
    </TimeslotTilesContainer>
  )
}

export default Tiles
