import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Primary450, Primary600, Primary700 } from '@mehilainen/design-system-tokens/colors'
import React from 'react'

import { CenteredRowFlex } from '../Layout/Layout'

type IconOrder = 'default' | 'icon-first' | 'icon-only'

const ExternalIcon = styled.img<{ size: string; order?: IconOrder }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin-left: ${({ order }) => (order === 'default' ? '10px' : 'inherit')};
  margin-right: ${({ order }) => (order === 'icon-first' ? '10px' : 'inherit')};
`

const isEmbeddedIcon = (icon: JSX.Element | string): icon is JSX.Element => {
  return typeof icon !== 'string'
}

const IconComponent = (icon: JSX.Element | string, order?: IconOrder) =>
  isEmbeddedIcon(icon) ? <>{icon}</> : <ExternalIcon src={icon} alt="" size="16px" order={order} />

const ClickableChip = styled.button`
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface Props {
  label: string
  ariaLabel?: string
  icon?: JSX.Element | string
  order?: IconOrder
  variant?: 'default' | 'important' | 'no-background'
  dataCy?: string
  className?: string
  onClick?(): void
}

const Chip: React.FC<React.PropsWithChildren<Props>> = ({
  ariaLabel,
  label,
  icon,
  order,
  onClick,
  className,
  dataCy,
}) => {
  const content = (
    <>
      {icon && order === 'icon-first' && IconComponent(icon, order)}
      {order !== 'icon-only' && <span data-cy={dataCy}>{label}</span>}
      {icon && order !== 'icon-first' && IconComponent(icon, order)}
    </>
  )

  if (onClick) {
    return (
      <ClickableChip aria-label={ariaLabel} className={className} onClick={onClick}>
        {content}
      </ClickableChip>
    )
  }

  return <CenteredRowFlex className={className}>{content}</CenteredRowFlex>
}

export default styled(Chip)`
  padding: 10px 16px;
  border-radius: 20px;
  background-color: ${(props) =>
    props.variant === 'important'
      ? `${Primary700}`
      : props.variant === 'no-background'
      ? 'none'
      : `${Primary450}`};
  color: white;
  font-size: 13px;
  font-weight: 350;

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      :hover {
        background-color: ${Primary600};
      }
      transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    `}

  ${(props) =>
    props.icon &&
    css`
      .MuiSvgIcon-root {
        ${props.order === 'icon-first'
          ? css`
              margin-right: 10px;
            `
          : props.order === 'icon-only'
          ? css`
              margin-right: 0px;
            `
          : css`
              margin-left: 10px;
            `}
        color: white;
      }
    `}
`
