import { SearchNode } from '../../__generated__/api'

export const flattenNodes = (nodes: SearchNode[]): SearchNode[] =>
  nodes.reduce((acc: SearchNode[], node: SearchNode) => {
    if (node.children) {
      const childrenResults = flattenNodes(node.children)
      childrenResults.forEach((childNode) => acc.push(childNode))
    }
    acc.push(node)
    return acc
  }, [])
