import type { Dayjs } from 'dayjs'
import { atom } from 'recoil'

import type {
  LanguageResult,
  PatientAgeGroup,
  PractitionerDetails,
  SearchNode,
} from '../../__generated__/api'
import { AppointmentType, TimeRange } from '../../__generated__/api'
import { Modal } from '../../__generated__/api'
import { AppointmentDuration } from '../../common/components/AppointmentLengthSelect/AppointmentLengthSelect'
import { GroupedFlexibleScheduleOption } from '../../common/components/AppointmentLengthSelect/FlexibleScheduleAppointmentLengthSelect'
import type { AppointmentListVariant } from '../../common/components/AppointmentList/AppointmentListToggle'
import { Gender } from '../../common/components/FilterOptions/types'
import { SearchTreeItem } from '../../common/components/Search/types'
import dayjs from '../../common/utils/dayjs/dayjs'

export enum AppointmentSearchMode {
  REGULAR = 'regular',
  CALLBACK = 'callback',
}

export const defaultSelectedDate = dayjs().utc().startOf('day')
export const selectedDateAtom = atom<Dayjs>({
  key: 'search-selectedDateAtom',
  default: defaultSelectedDate,
})
export const isUserSelectedDateAtom = atom<boolean>({
  key: 'search-isUserSelectedDate',
  default: false,
})

export const defaultOhcSearchNodeId =
  process.env.REACT_APP_DEFAULT_APPOINTMENT_OHC_SEARCH_NODE ?? ''
export const defaultSelectedSearchNodeId =
  process.env.REACT_APP_DEFAULT_APPOINTMENT_SEARCH_NODE ?? ''
export const defaultDentalSearchNodeId =
  process.env.REACT_APP_DEFAULT_APPOINTMENT_DENTAL_SEARCH_NODE ?? ''
export const selectedSearchNodeIdAtom = atom<string | null>({
  key: 'search-selectedSearchNodeIdAtom',
  default: null,
})

export const selectedSearchPractitionerAtom = atom<PractitionerDetails | undefined>({
  key: 'search-selectedSearchPractitionerIdAtom',
  default: undefined,
})

export const selectedFlexibleScheduleOptionAtom = atom<GroupedFlexibleScheduleOption | undefined>({
  key: 'search-selectedFlexibleScheduleOptionAtom',
  default: undefined,
})

export const defaultSelectedStandardScheduleOption = 'default'
export const selectedStandardScheduleOptionAtom = atom<AppointmentDuration>({
  key: 'search-selectedStandardScheduleOptionAtom',
  default: defaultSelectedStandardScheduleOption,
})

export const isUserSelectedNodeAtom = atom<boolean>({
  key: 'search-isUserSelectedNodeAtom',
  default: false,
})

export const defaultSelectedLocation = process.env.REACT_APP_DEFAULT_SELECTED_LOCATION?.split(
  ','
) ?? ['city2']
export const allLocationsSelection = ['ALL-LOCATIONS']
export const selectedNodeLocationAtom = atom<string[]>({
  key: 'search-selectedNodeLocationAtom',
  default: defaultSelectedLocation,
})

export const selectedPractitionerLocationAtom = atom<string[]>({
  key: 'search-selectedPractitionerLocationAtom',
  default: [],
})

export const defaultSelectedLanguage = null
export const selectedLanguageAtom = atom<LanguageResult | null>({
  key: 'search-selectedLanguageAtom',
  default: defaultSelectedLanguage,
})

export const defaultSelectedGender = Gender.UNSPECIFIED
export const selectedGenderAtom = atom<Gender>({
  key: 'search-selectedGenderAtom',
  default: defaultSelectedGender,
})

export const appointmentTypesNoCallback = Object.values(AppointmentType).filter(
  (type) => type !== AppointmentType.Callback
)
export const defaultSelectedAppointmentTypes = []
// NOTE: This tracks BOTH USER and AUTO-selection => any user selection should ALSO set 'userSelectedAppointmentTypesAtom'
export const selectedAppointmentTypesAtom = atom<AppointmentType[]>({
  key: 'search-selectedAppointmentTypesAtom',
  default: defaultSelectedAppointmentTypes,
})

// NOTE: This tracks USER selection only => any auto-selection should ONLY set 'selectedAppointmentTypesAtom'
export const userSelectedAppointmentTypesAtom = atom<AppointmentType[]>({
  key: 'search-userSelectedAppointmentTypesAtom',
  default: defaultSelectedAppointmentTypes,
})

export const defaultSelectedTimeRanges = []
export const selectedTimeRangesAtom = atom<TimeRange[]>({
  key: 'search-selectedTimeRangesAtom',
  default: defaultSelectedTimeRanges,
})

export const defaultSelectedPatientAgeGroup = null
export const selectedPatientAgeGroupAtom = atom<PatientAgeGroup | null>({
  key: 'search-selectedPatientAgeGroupAtom',
  default: defaultSelectedPatientAgeGroup,
})

export const defaultSelectedDurations = []
export const selectedDurationsAtom = atom<number[]>({
  key: 'search-selectedDurations',
  default: defaultSelectedDurations,
})

export const initialSearchHistoryRestoredAtom = atom<boolean>({
  key: 'search-initialSearchHistoryRestoredAtom',
  default: false,
})

export const dateModalOpenAtom = atom<boolean>({
  key: 'search-dateModalOpenAtom',
  default: false,
})

export const filterOptionsOpenAtom = atom<boolean>({
  key: 'search-filterOptionsOpenAtom',
  default: false,
})

export const defaultSelectedAppointmentListVariant = 'time'
export const selectedAppointmentListVariantAtom = atom<AppointmentListVariant>({
  key: 'search-selectedAppointmentListVariantAtom',
  default: defaultSelectedAppointmentListVariant,
})

export const searchBreadcrumb = atom<Array<SearchTreeItem<SearchNode>>>({
  key: 'search-searchBreadcrumbAtom',
  default: [],
})

export const defaultAppointmentSearchMode = AppointmentSearchMode.REGULAR
export const appointmentSearchModeAtom = atom<AppointmentSearchMode>({
  key: 'search-appointmentSearchTypeVariantAtom',
  default: defaultAppointmentSearchMode,
})

export const callbackModalOpenAtom = atom<boolean>({
  key: 'search-callbackModalOpenAtom',
  default: false,
})

export const searchServiceModalOpenAtom = atom<boolean>({
  key: 'search-searchServiceModalOpenAtom',
  default: false,
})

export const searchServiceModalAutoOpenAtom = atom<boolean>({
  key: 'search-searchServiceModalAutoOpenAtom',
  default: true,
})

export const searchNodeRedirectModal = atom<{ url: string; modalName: string } | null>({
  key: 'search-searchNodeRedirectModalName',
  default: null,
})
export const searchNodeModal = atom<Modal | null>({
  key: 'search-searchNodeModal',
  default: null,
})
export const controlSearchNodeModal = atom<['force', string] | 'skip' | null>({
  key: 'search-controlSearchNodeModal',
  default: null,
})
