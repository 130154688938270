import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

import {
  ElectronicHealthRecordSystem,
  NodeServiceType,
  PractitionerDetails,
  PractitionerNode,
} from '../../../__generated__/api'
import AppointmentLengthSelect, {
  AppointmentLengthOption,
  CallbackOption,
} from '../../../common/components/AppointmentLengthSelect/AppointmentLengthSelect'
import FlexibleScheduleAppointmentLengthSelect from '../../../common/components/AppointmentLengthSelect/FlexibleScheduleAppointmentLengthSelect'
import { DentalMaintenance } from '../../../common/components/DentalMaintenance/DentalMaintenance'
import Modal from '../../../common/components/Modal/Modal'
import PractitionerAppointmentInstructions from '../../../common/components/PractitionerAppointmentInstructions/PractitionerAppointmentInstructions'
import PractitionerHeader from '../../../common/components/PractitionerDetails/PractitionerHeader'
import SelectInput from '../../../common/components/SelectInput/SelectInput'
import { useFeatureFlags } from '../../../common/hooks/useFeatureFlags'
import { useOHC } from '../../../common/hooks/useOHC'
import {
  AppointmentSearchMode,
  appointmentSearchModeAtom,
  selectedFlexibleScheduleOptionAtom,
  selectedStandardScheduleOptionAtom,
  defaultSelectedStandardScheduleOption,
} from '../../../state/search/atoms'
import useSearchTarget from '../hooks/useSearchTarget'
import { groupFlexibleScheduleOptions } from '../utils'

interface Props {
  practitionerDetails: PractitionerDetails
}

const SearchDhPractitionerServiceSelect: React.FC<React.PropsWithChildren<Props>> = ({
  practitionerDetails,
}) => {
  const { t, i18n } = useTranslation()
  const { isOHCSide } = useOHC()

  const [selectedStandardScheduleOption, setSelectedStandardScheduleOption] = useRecoilState(
    selectedStandardScheduleOptionAtom
  )
  const [lengthOptionsOpen, setLengthOptionsOpen] = useState(
    selectedStandardScheduleOption !== defaultSelectedStandardScheduleOption ? false : true
  )

  const [selectedFlexibleScheduleOption, setSelectedFlexibleScheduleOption] = useRecoilState(
    selectedFlexibleScheduleOptionAtom
  )

  const [appointmentSearchMode, setAppointmentSearchMode] =
    useRecoilState(appointmentSearchModeAtom)

  const defaultStandardLengthOption: AppointmentLengthOption = useMemo(
    () => ({ duration: defaultSelectedStandardScheduleOption, description: [] }),
    []
  )

  const featureFlags = useFeatureFlags()

  const standardLengthOptions: AppointmentLengthOption[] = useMemo(
    () => [
      defaultStandardLengthOption,
      ...practitionerDetails.appointmentLengthOptions,
      ...practitionerDetails.nodes
        .filter((node: PractitionerNode) =>
          node.serviceType === NodeServiceType.Ohc ? isOHCSide : true
        )
        .map((node: PractitionerNode) => ({
          duration: `node-${node.id}` as `node-${string}`,
          description: [],
          nodeName: node.name,
          nodeId: node.id,
        })),
      ...(practitionerDetails.allowsCallbackReservations && featureFlags?.callbackRequestsEnabled
        ? [CallbackOption]
        : []),
    ],
    [defaultStandardLengthOption, practitionerDetails, featureFlags, isOHCSide]
  )

  const flexibleLengthOptions = useMemo(
    () => groupFlexibleScheduleOptions(practitionerDetails, i18n.language),
    [practitionerDetails, i18n.language]
  )

  const instructionsForcedOpen = useMemo(
    () =>
      (practitionerDetails.hasFlexibleSchedule && !selectedFlexibleScheduleOption) ||
      (!practitionerDetails.hasFlexibleSchedule &&
        !selectedStandardScheduleOption &&
        practitionerDetails.appointmentLengthOptions.length > 0),
    [practitionerDetails, selectedFlexibleScheduleOption, selectedStandardScheduleOption]
  )

  const inputValue = useMemo(() => {
    if (appointmentSearchMode === AppointmentSearchMode.CALLBACK) {
      return t('component.searchPractitionerServiceSelect.callbackText')
    }
    const flexibleScheduleText = selectedFlexibleScheduleOption
      ? selectedFlexibleScheduleOption.availableDurations.length === 1
        ? t('component.searchPractitionerServiceSelect.flexibleScheduleText', {
            service: selectedFlexibleScheduleOption.serviceName,
            duration: selectedFlexibleScheduleOption.availableDurations[0],
          })
        : selectedFlexibleScheduleOption.serviceName
      : undefined

    const standardScheduleText =
      selectedStandardScheduleOption === defaultSelectedStandardScheduleOption
        ? t('component.searchPractitionerServiceSelect.defaultDurationText')
        : typeof selectedStandardScheduleOption === 'number'
        ? t('component.searchPractitionerServiceSelect.standardScheduleText', {
            duration: selectedStandardScheduleOption,
          })
        : standardLengthOptions.find((option) => option.duration === selectedStandardScheduleOption)
            ?.nodeName

    return flexibleScheduleText ?? standardScheduleText
  }, [
    t,
    selectedFlexibleScheduleOption,
    selectedStandardScheduleOption,
    appointmentSearchMode,
    standardLengthOptions,
  ])

  const shouldDisplay = useMemo(
    () =>
      practitionerDetails.hasFlexibleSchedule ||
      practitionerDetails.appointmentLengthOptions.length > 0 ||
      practitionerDetails.specialties.length > 1 ||
      practitionerDetails.allowsCallbackReservations,
    [practitionerDetails]
  )

  const shouldHideLengthOptions = useMemo(
    () =>
      !practitionerDetails.hasFlexibleSchedule &&
      practitionerDetails.appointmentLengthOptions.length === 0 &&
      !practitionerDetails.allowsCallbackReservations,
    [practitionerDetails]
  )

  useEffect(() => {
    if (instructionsForcedOpen) {
      setLengthOptionsOpen(true)
    }
  }, [instructionsForcedOpen])

  useEffect(() => {
    if (practitionerDetails.hasFlexibleSchedule && flexibleLengthOptions.length === 1) {
      setSelectedFlexibleScheduleOption(flexibleLengthOptions[0])
      if (flexibleLengthOptions[0].availableDurations.length === 1) {
        setLengthOptionsOpen(false)
      }
    }
  }, [
    practitionerDetails.hasFlexibleSchedule,
    flexibleLengthOptions,
    setLengthOptionsOpen,
    setSelectedFlexibleScheduleOption,
  ])

  const onSelect = (option: AppointmentLengthOption) => {
    if (option.isCallback) {
      setAppointmentSearchMode(AppointmentSearchMode.CALLBACK)
    } else {
      setSelectedStandardScheduleOption(option.duration)
      setAppointmentSearchMode(AppointmentSearchMode.REGULAR)
    }
  }

  const onModalClose = () => {
    if (instructionsForcedOpen) {
      return
    }
    setLengthOptionsOpen(false)
  }

  if (!shouldDisplay) {
    return null
  }

  return (
    <>
      <SelectInput
        label={t('component.searchPractitionerServiceSelect.label')}
        value={inputValue}
        onClick={() => {
          setLengthOptionsOpen(true)
        }}
        dataCy="searchPractitionerServiceSelect"
      />
      {!shouldHideLengthOptions && (
        <Modal
          open={lengthOptionsOpen}
          onClose={onModalClose}
          primaryButtonText={t(
            'component.practitionerAppointmentInstructions.showAvailableAppointments'
          )}
          onPrimaryButtonClick={() => setLengthOptionsOpen(false)}
          primaryButtonDisabled={
            practitionerDetails.hasFlexibleSchedule && !selectedFlexibleScheduleOption
          }
          primaryButtonCy="appointmentLengthOptionSelect"
          secondaryButtonText={t('common.back')}
          onSecondaryButtonClick={() => setLengthOptionsOpen(false)}
        >
          <PractitionerAppointmentInstructions
            practitionerDetails={practitionerDetails}
            lengthSelectComponent={
              <>
                {practitionerDetails.hasFlexibleSchedule ? (
                  <FlexibleScheduleAppointmentLengthSelect
                    value={selectedFlexibleScheduleOption}
                    onSelect={setSelectedFlexibleScheduleOption}
                    options={flexibleLengthOptions}
                  />
                ) : (
                  <AppointmentLengthSelect
                    options={standardLengthOptions}
                    value={
                      appointmentSearchMode === AppointmentSearchMode.CALLBACK
                        ? CallbackOption
                        : standardLengthOptions.find(
                            (option) => option.duration === selectedStandardScheduleOption
                          ) ?? defaultStandardLengthOption
                    }
                    onSelect={onSelect}
                  />
                )}
              </>
            }
          />
        </Modal>
      )}
    </>
  )
}

const SearchAssisDentPractitionerServiceSelect: React.FC<React.PropsWithChildren<Props>> = ({
  practitionerDetails,
}) => {
  const { t } = useTranslation()
  const [selectedStandardScheduleOption, setSelectedStandardScheduleOption] = useRecoilState(
    selectedStandardScheduleOptionAtom
  )
  const isReasonSelected =
    typeof selectedStandardScheduleOption === 'string' &&
    selectedStandardScheduleOption.startsWith('reason-')
  const [modalOpen, setModalOpen] = useState(isReasonSelected ? false : true)

  const options: AppointmentLengthOption[] = useMemo(() => {
    if (!practitionerDetails.assisDentReasons) {
      return []
    }
    return practitionerDetails.assisDentReasons.map((reason) => ({
      duration: `reason-${reason.id}` as `reason-${string}`,
      description: [],
      reasonId: reason.id,
      reasonName: reason.name,
    }))
  }, [practitionerDetails])

  const displayDentalMaintenance = practitionerDetails.isAssisDentMaintenance

  const forceModalOpen = !options.find(
    (option) => selectedStandardScheduleOption === `reason-${option.reasonId}`
  )

  const value =
    options.find((option) => selectedStandardScheduleOption === `reason-${option.reasonId}`)
      ?.reasonName ?? ''

  const { setSearchTarget, breadcrumb } = useSearchTarget()

  return (
    <>
      <SelectInput
        label={t('component.searchPractitionerServiceSelect.label')}
        value={value}
        onClick={() => {
          setModalOpen(true)
        }}
        dataCy="searchPractitionerServiceSelect"
      />
      <Modal
        open={modalOpen || forceModalOpen}
        onClose={() => {
          if (forceModalOpen) {
            setSearchTarget(
              breadcrumb.length >= 2
                ? breadcrumb[breadcrumb.length - 2].searchTarget
                : breadcrumb[0].searchTarget
            )
          } else {
            setModalOpen(false)
          }
        }}
        primaryButtonText={
          displayDentalMaintenance
            ? t('common.close')
            : t('component.practitionerAppointmentInstructions.showAvailableAppointments')
        }
        onPrimaryButtonClick={() => setModalOpen(false)}
        primaryButtonDisabled={displayDentalMaintenance || forceModalOpen}
        primaryButtonCy="appointmentLengthOptionSelect"
        secondaryButtonText={displayDentalMaintenance ? undefined : t('common.back')}
        onSecondaryButtonClick={() => {
          if (forceModalOpen) {
            setSearchTarget(
              breadcrumb.length >= 2
                ? breadcrumb[breadcrumb.length - 2].searchTarget
                : breadcrumb[0].searchTarget
            )
          } else {
            setModalOpen(false)
          }
        }}
      >
        {displayDentalMaintenance ? (
          <>
            <PractitionerHeader
              practitioner={practitionerDetails}
              showDisplayAppointmentsButton={false}
            />
            <DentalMaintenance variant="nested" />
          </>
        ) : (
          <PractitionerAppointmentInstructions
            heading={t('component.searchPractitionerServiceSelect.assisDentSelectService')}
            practitionerDetails={practitionerDetails}
            lengthSelectComponent={
              <AppointmentLengthSelect
                options={options}
                value={options.find((option) => option.duration === selectedStandardScheduleOption)}
                onSelect={(option) => setSelectedStandardScheduleOption(option.duration)}
              />
            }
          />
        )}
      </Modal>
    </>
  )
}

const SearchPractitionerServiceSelect: React.FC<React.PropsWithChildren<Props>> = ({
  practitionerDetails,
}) => {
  if (practitionerDetails.primarySource === ElectronicHealthRecordSystem.Dh) {
    return <SearchDhPractitionerServiceSelect practitionerDetails={practitionerDetails} />
  } else {
    return <SearchAssisDentPractitionerServiceSelect practitionerDetails={practitionerDetails} />
  }
}

export default SearchPractitionerServiceSelect
