import { Dayjs } from 'dayjs'
import React, { createContext, ReactNode, useContext, useCallback } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import DateModal from '../../../common/components/DateSelect/DateModal'
import { CalendarAvailability } from '../../../common/components/DateSelect/DatePickerCalendar'
import { useDeepCompareCallback } from '../../../common/hooks/useDeepCompare'
import { useOHC } from '../../../common/hooks/useOHC'
import api from '../../../common/services/api'
import dayjs from '../../../common/utils/dayjs/dayjs'
import {
  dateModalOpenAtom,
  selectedDateAtom,
  isUserSelectedDateAtom,
  appointmentSearchModeAtom,
  AppointmentSearchMode,
} from '../../../state/search/atoms'
import {
  nodeCalendarSearchParamsSelector,
  practitionerSearchParamsSelector,
} from '../../../state/search/selectors'

import useSearchTarget, { SearchTargetValue } from './useSearchTarget'

type SearchCalendarContextType = {
  calendarModalOpen: boolean
  setCalendarModalOpen(open: boolean): void
  selectedDate: Dayjs
  setSelectedDate(date: Dayjs): void
  calendarDataFetcher(date: Dayjs): Promise<CalendarAvailability[]>
}

const SearchCalendarContext = createContext<SearchCalendarContextType>({
  calendarModalOpen: false,
  setCalendarModalOpen: () => {
    /* Placeholder */
  },
  selectedDate: dayjs(),
  setSelectedDate: () => {
    /* Placeholder */
  },
  calendarDataFetcher: () => Promise.resolve([]),
})

export const SearchCalendarProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const nodeSearchParams = useRecoilValue(nodeCalendarSearchParamsSelector)
  const practitionerSearchParams = useRecoilValue(practitionerSearchParamsSelector)
  const { isOHCSide } = useOHC()
  const { searchTarget } = useSearchTarget()

  const [calendarModalOpen, setCalendarModalOpen] = useRecoilState(dateModalOpenAtom)
  const [selectedDate, setSelectedDateRecoil] = useRecoilState(selectedDateAtom)
  const setIsUserSelectedDate = useSetRecoilState(isUserSelectedDateAtom)
  const appointmentSearchMode = useRecoilValue(appointmentSearchModeAtom)

  const nodeCalendarDataFetcher = useDeepCompareCallback(
    async (date: Dayjs) => {
      const data = await api.v1
        .nodeSearchCalendar({
          ...nodeSearchParams,
          startDate: date.toJSON(),
        })
        .then((res) => res.data ?? [])

      return [...data.map((slot) => ({ date: dayjs(slot.date), isAvailable: slot.isAvailable }))]
    },
    [nodeSearchParams]
  )

  const practitionerCalendarDataFetcher = useDeepCompareCallback(
    async (date: Dayjs) => {
      if (!practitionerSearchParams) {
        return []
      }
      const endpoint =
        appointmentSearchMode === AppointmentSearchMode.CALLBACK
          ? api.v1.practitionerCallbackSearchCalendar
          : api.v1.practitionerAppointmentSearchCalendar

      const data = await endpoint({
        ...practitionerSearchParams,
        startDate: date.toJSON(),
        isOhc: isOHCSide,
      }).then((res) => res.data)

      return [...data.map((slot) => ({ date: dayjs(slot.date), isAvailable: slot.isAvailable }))]
    },
    [practitionerSearchParams, appointmentSearchMode]
  )

  const calendarDataFetcher =
    searchTarget.value === SearchTargetValue.Practitioner
      ? practitionerCalendarDataFetcher
      : nodeCalendarDataFetcher

  const setSelectedDate = useCallback(
    (date: Dayjs) => {
      setSelectedDateRecoil(date)
      setIsUserSelectedDate(true)
    },
    [setIsUserSelectedDate, setSelectedDateRecoil]
  )

  return (
    <SearchCalendarContext.Provider
      value={{
        calendarModalOpen,
        setCalendarModalOpen,
        selectedDate,
        setSelectedDate,
        calendarDataFetcher,
      }}
    >
      {children}
      <DateModal
        open={calendarModalOpen}
        calendarDataFetcher={calendarDataFetcher}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleClose={() => setCalendarModalOpen(false)}
      />
    </SearchCalendarContext.Provider>
  )
}

const useSearchCalendar = () => {
  return useContext(SearchCalendarContext)
}

export default useSearchCalendar
