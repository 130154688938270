import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Gray100,
  Gray300,
  Gray400,
  Gray600,
  Gray900,
  Primary500,
} from '@mehilainen/design-system-tokens/colors'
import { Check } from '@mehilainen/mds-customer/icons'
import equal from 'fast-deep-equal'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { scale } from '../../utils/scale'
import { ColumnFlex } from '../Layout/Layout'

const Container = styled.div``

const CheckMarkContainer = styled.div<CheckMarkProps>`
  grid-area: checkbox;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  border: 1px solid ${Gray400};
  border-radius: 50%;
  margin-right: 12px;

  ${(props) =>
    props.checked &&
    css`
      border: none;
      background-color: ${Primary500};
      .MuiSvgIcon-root {
        color: white;
      }
    `}
`

const StampContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
const DescContainer = styled.div`
  grid-area: description;
`

const OptionContainer = styled(ColumnFlex)<{
  checked: boolean
  disabled: boolean
  focused: boolean
  hasStamp: boolean
}>`
  position: relative;
  color: ${(props) => (props.disabled ? `${Gray600}` : `${Gray900}`)};
  background: ${(props) => (props.disabled ? `${Gray100}` : 'white')};
  padding: ${scale(2)};
  outline: ${(props) => (props.checked ? `2px solid ${Primary500}` : `1px solid ${Gray300}`)};

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        outline: 2px solid ${Primary500};

        ${CheckMarkContainer} {
          border: 2px solid ${Primary500};
        }
      }
    `}

  ${(props) =>
    !props.disabled &&
    props.focused &&
    css`
      outline: 2px solid ${Primary500};

      ${CheckMarkContainer} {
        border: 2px solid ${Primary500};
        outline: 2px solid ${Primary500};
        outline-offset: 1px;
      }
    `}
`

const Option = styled.div<{
  disabled: boolean
  hasDescription: boolean
}>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: grid;
  grid-template-columns: 1fr 25fr;
  grid-template-rows: minmax(30px, 1fr);
  align-items: center;

  grid-template-areas: ${(props) =>
    props.hasDescription
      ? `'checkbox auto'
    '. description'`
      : `'checkbox auto'`};
`

const OptionWrapper = styled.label`
  ${OptionContainer} {
    border-radius: ${scale(0.5)};
    margin-bottom: ${scale(0.75)};
  }
`

const StyledRadioButton = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`
StyledRadioButton.defaultProps = { type: 'radio' }

interface CheckMarkProps {
  checked: boolean
}

const CheckMark: React.FC<React.PropsWithChildren<CheckMarkProps>> = ({ checked }) => {
  return <CheckMarkContainer checked={checked}>{checked && <Check />}</CheckMarkContainer>
}

interface WrapperOptionProps<T> {
  option: T
  checked: boolean
  disabled: boolean
  dataCy?: string
  onChange(value: T): void
  renderOptionHeader(option: T, checked: boolean): ReactNode
  renderOptionDescription?(option: T, checked: boolean): ReactNode
  renderStamp?(option: T): ReactNode
}

const WrappedOption = <T,>({
  option,
  checked,
  disabled,
  dataCy,
  renderOptionHeader,
  renderOptionDescription,
  renderStamp,
  onChange,
}: WrapperOptionProps<T>) => {
  const ref = useRef(null)
  const [focused, setFocused] = useState<boolean>(false)
  const shouldRenderDescription = typeof renderOptionDescription === 'function'
  const shouldRenderStamp = typeof renderStamp === 'function' && renderStamp(option) !== undefined
  const optionId = `option-${uuid()}` // TODO: switch to useId hook in React 18
  const optionHeaderId = `option-header-${uuid()}` // TODO: switch to useId hook in React 18

  useEffect(() => {
    const focusin = (event: { target: unknown }) => {
      if (event.target === ref.current) {
        setFocused(true)
      } else {
        setFocused(false)
      }
    }
    window.addEventListener('focusin', focusin)
    return () => {
      window.removeEventListener('focusin', focusin)
    }
  }, [ref, setFocused])

  return (
    <OptionWrapper aria-labelledby={optionHeaderId} data-cy={dataCy}>
      <StyledRadioButton
        id={optionId}
        checked={checked}
        onChange={() => onChange(option)}
        disabled={disabled}
        ref={ref}
      />
      <OptionContainer
        focused={focused}
        checked={checked}
        disabled={disabled}
        hasStamp={shouldRenderStamp}
      >
        {shouldRenderStamp && <StampContainer>{renderStamp(option)}</StampContainer>}
        <Option disabled={disabled} hasDescription={shouldRenderDescription}>
          {<CheckMark checked={checked} />}
          <label id={optionHeaderId} htmlFor={optionId}>
            {renderOptionHeader(option, checked)}
          </label>
          {shouldRenderDescription && (
            <DescContainer>{renderOptionDescription(option, checked)}</DescContainer>
          )}
        </Option>
      </OptionContainer>
    </OptionWrapper>
  )
}

interface Props<T> {
  options: T[]
  value?: T | null
  onChange(value: T): void
  renderOptionHeader(option: T, checked: boolean): ReactNode
  renderOptionDescription?(option: T, checked: boolean): ReactNode
  renderStamp?(option: T): ReactNode
  isDisabled?(option: T): boolean
  isSelected?(selected: T, value?: T | null): boolean
  getKey?(option: T): string
  className?: string
  dataCy?: string
}

const RadioButtonGroup = <T,>({
  options,
  value,
  renderOptionHeader,
  renderOptionDescription,
  renderStamp,
  onChange,
  isSelected = equal,
  isDisabled = () => false,
  getKey,
  className,
}: Props<T>) => {
  return (
    <Container className={className}>
      {options.map((option, index) => {
        const checked = isSelected(option, value)
        const disabled = isDisabled(option)
        return (
          <WrappedOption
            key={getKey ? getKey(option) : `radioButtonGroup-option-${index}`}
            dataCy={getKey ? getKey(option) : `radioButtonGroup-option-${index}`}
            checked={checked}
            disabled={disabled}
            option={option}
            onChange={onChange}
            renderOptionHeader={renderOptionHeader}
            renderOptionDescription={renderOptionDescription}
            renderStamp={renderStamp}
          />
        )
      })}
    </Container>
  )
}

export default RadioButtonGroup
