import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import ServiceSelect from '../../../common/components/SearchSelect/SearchSelect'
import { useApi } from '../../../common/hooks/useApi'
import { useNode } from '../../../common/hooks/useNode'
import { useOHC } from '../../../common/hooks/useOHC'
import api from '../../../common/services/api'
import { selectedSearchPractitionerAtom } from '../../../state/search/atoms'
import { getSelectedNodeOrDefault } from '../../../state/search/selectors'
import useSearchTarget, { SearchTargetValue } from '../hooks/useSearchTarget'

const SearchServiceSelect: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { i18n } = useTranslation()
  const selectedSearchNodeId = useRecoilValue(getSelectedNodeOrDefault)
  const selectedPractitioner = useRecoilValue(selectedSearchPractitionerAtom)
  const { setSearchTarget } = useSearchTarget()
  const { isOHCSide, ohcAllowedStatus } = useOHC()

  const shouldMakeRequest = isOHCSide ? ohcAllowedStatus === 'allowed' : true
  const { node: selectedSearchNode } = useNode(selectedSearchNodeId)
  const { data: listedNodes } = useApi(
    isOHCSide && ohcAllowedStatus === 'allowed' ? api.v1.getOhcNodes : api.v1.searchNodes,
    { lang: i18n.language },
    [],
    isOHCSide ? ohcAllowedStatus === 'allowed' : true
  )
  const { data: favoriteNodes } = useApi(
    api.v1.favoriteNodes,
    { menu: isOHCSide ? 'ohc' : 'private', lang: i18n.language },
    [],
    shouldMakeRequest
  )

  return (
    <ServiceSelect
      listedNodes={listedNodes}
      favoriteNodes={favoriteNodes}
      inputValue={selectedPractitioner?.name ?? selectedSearchNode?.name}
      selected={selectedSearchNode}
      onSelect={(node) => {
        setSearchTarget({ id: node.id, value: SearchTargetValue.Node })
      }}
      onPractitionerSelect={(id) => {
        setSearchTarget({ id, value: SearchTargetValue.Practitioner })
      }}
    />
  )
}

export default SearchServiceSelect
