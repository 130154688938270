import styled from '@emotion/styled'
import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useSetRecoilState } from 'recoil'

import {
  AppointmentSearchMode,
  appointmentSearchModeAtom,
  callbackModalOpenAtom,
  searchServiceModalOpenAtom,
} from '../../../state/search/atoms'
import Modal from '../Modal/Modal'

const Container = styled.div``

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
`

const StyledH1 = styled.h1`
  font-size: 18px;
  font-weight: 450;
`

const CallbackModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()

  const [callbackModalOpen, setCallbackModalOpen] = useRecoilState(callbackModalOpenAtom)
  const setServiceModalOpen = useSetRecoilState(searchServiceModalOpenAtom)
  const [appointmentSearchMode, setAppointmentSearchMode] =
    useRecoilState(appointmentSearchModeAtom)

  const closeModal = (openServiceModal: boolean) => {
    setCallbackModalOpen(false)

    if (openServiceModal) {
      setServiceModalOpen(true)
      if (appointmentSearchMode !== AppointmentSearchMode.CALLBACK) {
        setAppointmentSearchMode(AppointmentSearchMode.CALLBACK)
      }
    }
  }

  return (
    <Modal open={callbackModalOpen} onClose={() => closeModal(false)} fullWidth>
      <Container>
        <ContentContainer>
          <StyledH1>{t('component.callbackModal.title')}</StyledH1>
          <p>{t('component.callbackModal.description')}</p>
          <p>{t('component.callbackModal.additionalInfo')}</p>
          <Button size="large" onClick={() => closeModal(true)}>
            {t('component.callbackModal.buttonText')}
          </Button>
        </ContentContainer>
      </Container>
    </Modal>
  )
}

export default CallbackModal
