export const mehiAvCookie = process.env.REACT_APP_MEHI_AV_COOKIE_NAME || 'mehi.av'
export const jwtKey = 'mehi.token'

export const daysInFutureToSearch = 60

// Color definitions not available in @mehilainen/design-system-tokens
export const LangOptionButtonBorderColor = 'rgba(0, 0, 0, 0.1)'
export const FormControlLabelBorderColor = 'rgba(0, 0, 0, 0.17)'
export const BaseTileBoxShadow = '0px 0px 6px rgba(0, 0, 0, 0.08)'
export const AppointmentDetailsBackground = 'rgba(233, 250, 242, 0.5)'

export const ExternalBannerBackground = '#4a154b'
export const EMPLOYMENT_START_THRESHOLD_DAYS = 31
export const Error200 = '#fcd5d5'
